import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import type { IssueDetails } from '~/api/modules/issues/services/getIssueDetails/getIssueDetails.types'

dayjs.extend(duration)

type DifferenceResult = {
  years: number
  months: number
}

const PERIODICITY_TEXT_MAP = {
  Měsíčně: 'Každý měsíc',
  Čtvrtletně: 'Každé 3 měsíce',
  Půlročně: 'Každých 6 měsíců',
  Ročně: 'Každý rok',
  Jednorázově: 'Jednorázově',
} satisfies Record<Required<IssueDetails>['returnsPeriodicity'], string>

function getDifferenceInYearsAndMonths(start: Dayjs, end: Dayjs): DifferenceResult {
  const years = end.diff(start, 'year')
  start = start.add(years, 'years')
  const months = end.diff(start, 'month')

  return {
    years,
    months,
  }
}

function getDeclinedWord(number: number, variants: [string, string, string]): string {
  if (number === 1) {
    return variants[0]
  }

  if (number > 1 && number < 5) {
    return variants[1]
  }

  return variants[2]
}

export function useInvestmentExample(issue: Ref<IssueDetails>): ComputedRef<string> {
  const { t } = useI18n()

  const timeDifferenceText = computed(() => {
    const maturityDate = dayjs(issue.value.maturityDate)
    const diff = getDifferenceInYearsAndMonths(dayjs(), maturityDate)

    const yearWord = getDeclinedWord(diff.years, ['rok', 'roky', 'let'])
    const monthWord = getDeclinedWord(diff.months, ['měsíc', 'měsíce', 'měsíců'])

    const yearText = diff.years > 0 ? `${diff.years} ${yearWord}` : ''
    const monthText = diff.months > 0 ? `${diff.months} ${monthWord}` : ''

    return [yearText, monthText].filter(Boolean).join(' a ')
  })

  const bondsCount = computed<number>(() => {
    if (issue.value.nominalValue! < 500) {
      return 1000
    }

    if (issue.value.nominalValue! > 500000) {
      return 1
    }

    return 2
  })

  const calculator = computed(() => useCalculator(bondsCount.value, issue))

  const totalNominalValue = computed(() => `${useMoneyFormat(issue.value.nominalValue! * bondsCount.value, true)} ${t(`common.currencies.${issue.value.currency}`)}`)

  const formattedVariableYieldInterestRateType = computed(() => {
    return issue.value.interestRate?.variableYieldInterestRateType?.replace('_', ' ')
  })

  const formattedVariableYieldMargin = computed(() => {
    return usePercentageFormat(issue.value.interestRate?.variableYieldMargin?.toString() || '')
  })

  const introText = computed(() => {
    let bondWord = 'dluhopisy'
    if (bondsCount.value === 1) {
      bondWord = 'dluhopis'
    }
    else if (bondsCount.value >= 5 || bondsCount.value === 0) {
      bondWord = 'dluhopisů'
    }

    const totalValue = useMoneyFormat(issue.value.nominalValue! * bondsCount.value, true)
    const currency = t(`common.currencies.${issue.value.currency}`)

    return `Koupíte si například <strong>${bondsCount.value} ${bondWord}</strong> o celkové hodnotě <strong>${totalValue} ${currency}</strong> a s celkovou splatností za <strong>${timeDifferenceText.value}</strong>.`
  })

  const periodicityInfoText = computed(() => {
    if (!issue.value.returnsPeriodicity) {
      return ''
    }

    if (issue.value.interestRate?.variableYieldInterestRateType) {
      return `<strong>${PERIODICITY_TEXT_MAP[issue.value.returnsPeriodicity]}</strong> Vám na běžný účet <strong>automaticky přijde výnos odpovídající aktuální hodnotě ${formattedVariableYieldInterestRateType.value} navýšený o ${formattedVariableYieldMargin.value} %</strong> (podléhá 15% zdanění&#42;, které za Vás provede emitent).`
    }

    return `<strong>${PERIODICITY_TEXT_MAP[issue.value.returnsPeriodicity]}</strong> Vám na běžný účet <strong>automaticky přijde ${calculator.value.result.value.periodic}</strong> (po 15% zdanění&#42;, které za Vás provede emitent).`
  })

  const earlyRepaymentText = computed(() => {
    return issue.value.hasEarlyRepayment
      ? '<strong>V průběhu investice máte možnost dluhopisy předčasně odprodat a získat tak celou nebo část své investice zpět. Přesná pravidla si můžete zjistit v Emisních podmínkách.</strong>'
      : ''
  })

  const repaymentText = computed(() =>
    `${issue.value.hasEarlyRepayment ? 'Pakliže nevyužijete možnosti předčasného odprodeje, <strong>za' : '<strong>Za'} ${useCapitalize(timeDifferenceText.value)}</strong> Vám emitent <strong>automaticky vyplatí zpět zainvestovaných ${totalNominalValue.value}</strong>.`,
  )

  const profitText = computed(() =>
    `<strong>Celkově realizujete čistý zisk</strong> za ${timeDifferenceText.value} <strong>${calculator.value.result.value.wholePeriod}</strong> (po zdanění 15 %&#42;).`,
  )

  const calculatorText = '<strong>Spočítejte si do detailu</strong>, kolik byste mohli vydělat investicí do tohoto dluhopisu <strong>v přiložené kalkulačce</strong>.'

  const investmentText = computed(() => `<ul>
  <li>${introText.value}</li>
  ${periodicityInfoText.value ? `<li>${periodicityInfoText.value}</li>` : ''}
  ${earlyRepaymentText.value ? `<li>${earlyRepaymentText.value}</li>` : ''}
  <li>${repaymentText.value}</li>
  ${!issue.value.interestRate?.variableYieldInterestRateType
    ? `  <li>${profitText.value}</li>
  <li>${calculatorText}</li>`
    : ''}
</ul>`)

  return investmentText
}
