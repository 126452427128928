import dayjs from 'dayjs'
import type { IssueWarning } from './types/types'
import { APP_DATE_FORMAT } from '~/constants/common'
import type { Company } from '~/api/types'

export type useLiquidationOutput = Omit<Company['lustration']['liquidation'], 'lastCheck'> & { show: boolean, startDate?: string, endDate?: string, lustration: IssueWarning }

export function useLiquidation(company?: Company): useLiquidationOutput | null {
  if (!company?.lustration?.liquidation) {
    return null
  }

  const { liquidation } = company.lustration

  const { t } = useI18n()

  const startDate = liquidation.startDate ? dayjs(liquidation.startDate).format(APP_DATE_FORMAT) : undefined
  const endDate = liquidation.endDate ? dayjs(liquidation.endDate).format(APP_DATE_FORMAT) : undefined

  return {
    ...liquidation,
    show: Boolean(liquidation.hasLiquidation),
    startDate,
    endDate,
    lustration: {
      title: t('issue.lustration.liquidation.title', { companyName: company.name || '' }),
      tooltip: t('issue.lustration.liquidation.tooltip.from') + (startDate || ''),
      url: liquidation?.url,
    },
  }
}
