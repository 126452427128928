<script setup lang="ts">
import type { Collateral } from '~~/../admin/types/components/Collateral'
import type { CollateralVariable } from '~~/../admin/types/components/CollateralVariable'
import { IssueCollateralTextVariablesType, IssuesCollateralType } from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'

const props = defineProps<{
  collaterals: Collateral[]
  showDetails: boolean
}>()

const { t, te } = useI18n()

function removeDuplicateDots(sentences: string) {
  return sentences.replace(/\s\./g, '.').replace(/\.\./g, '.')
}

function getHedgingAgentText(hedgingAgent?: string) {
  let result = t(`issue.collateralTypes.description.${IssuesCollateralType.hedgingAgent}`)

  if (hedgingAgent) {
    result += ` ${t('issue.collateralTypes.description.hedgingAgent', {
      hedgingAgent,
    })}`
  }

  return result
}

function getCollateralVariableTextByType(type: IssueCollateralTextVariablesType, textVariables: CollateralVariable[]) {
  const result = textVariables.find(text => text.type === type)?.value || ''

  return result
}

const collateralsWithHardcodedText = computed(() => props.collaterals.map((collateral: Collateral) => ({
  ...collateral,

  hardcodedText: te(`issue.collateralTypes.description.${collateral.type}`)
    ? removeDuplicateDots(t(`issue.collateralTypes.description.${collateral.type}`, {
      guaranteeDeclarationOfLegalEntity: getCollateralVariableTextByType(IssueCollateralTextVariablesType.guaranteeDeclarationOfLegalEntity, collateral.textVariables),
      ownerGuaranteeStatement: getCollateralVariableTextByType(IssueCollateralTextVariablesType.ownerGuaranteeStatement, collateral.textVariables),
    }))
    : '',

  ...(collateral.type === IssuesCollateralType.hedgingAgent
    ? {
        hardcodedText: removeDuplicateDots(
          getHedgingAgentText(
            getCollateralVariableTextByType(IssueCollateralTextVariablesType.hedgingAgent, collateral.textVariables),
          ),
        ),
      }
    : {}),
})))
</script>

<template>
  <div
    v-if="collaterals"
    class="flex flex-col"
    :class="{
      'gap-y-4': !showDetails,
      'gap-y-8': showDetails,
    }"
  >
    <IssueDetailsV2CollateralsRow
      v-for="(collateral, index) in collateralsWithHardcodedText"
      :key="index"
      :collateral="collateral"
      :is-extended="showDetails"
    />
  </div>
</template>
