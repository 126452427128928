import type { ShortIssueWithId } from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import {
  IssuesCollateralType,
} from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import { issuesFiltersRanges } from '~/stores/issues/constants'
import type { IssueWithLoadingState, IssuesListFilters } from '~/stores/issues/types'
import { IssuesFiltersKeys } from '~/stores/issues/types'

export function mapIssues(issues: ShortIssueWithId[], loading?: boolean): IssueWithLoadingState[] {
  return issues.map(issue => ({
    id: issue.id,
    issue,
    loading: Boolean(loading),
  }))
}

const filtersValidators = {
  [IssuesFiltersKeys.HasEarlyRepayment]: booleanValidator,
  [IssuesFiltersKeys.IsProspectusApprovedByCNB]: booleanValidator,
  [IssuesFiltersKeys.CanBeBoughtOnline]: booleanValidator,
  [IssuesFiltersKeys.StateBonds]: booleanValidator,
  [IssuesFiltersKeys.DmatChecks]: numberValidator,
  [IssuesFiltersKeys.OnlyOur]: booleanValidator,

  [IssuesFiltersKeys.AnnualInterestRate](value: unknown) {
    return probablyNumberArrayValidator(value, 2, issuesFiltersRanges[IssuesFiltersKeys.AnnualInterestRate])
  },

  [IssuesFiltersKeys.MfcrScore](value: unknown) {
    return probablyNumberArrayValidator(value, 2, issuesFiltersRanges[IssuesFiltersKeys.MfcrScore])
  },

  [IssuesFiltersKeys.IssueMaturity](value: unknown) {
    return probablyNumberArrayValidator(value, 2, issuesFiltersRanges[IssuesFiltersKeys.IssueMaturity])
  },

  [IssuesFiltersKeys.CollateralTypes](value: unknown) {
    const allowedTypes = Object.values(IssuesCollateralType) as string[]

    if (Array.isArray(value)) {
      return value.every(v => allowedTypes.includes(v))
    }

    return typeof value === 'string' && allowedTypes.includes(value)
  },
} satisfies Partial<Record<IssuesFiltersKeys, (value: unknown) => boolean>>

export function filterQueryValidator(filter: Partial<IssuesListFilters>) {
  return Object.entries(filter).every(([key, value]) => {
    const validator = filtersValidators[key as IssuesFiltersKeys]
    return validator ? validator(value) : true
  })
}

function numberValidator(value: unknown, range?: { min?: number, max?: number }) {
  const min = range?.min ?? Number.MIN_SAFE_INTEGER
  const max = range?.max ?? Number.MAX_SAFE_INTEGER

  const updatedValue = Number(value)

  return !Number.isNaN(updatedValue) && updatedValue >= min && updatedValue <= max && Number.isFinite(updatedValue)
}

function booleanValidator(value: unknown) {
  return typeof value === 'boolean'
}

function probablyNumberArrayValidator(value: unknown, length = 2, range?: { min?: number, max?: number }) {
  if (Array.isArray(value)) {
    return value.length === length && value.every(v => numberValidator(v, range))
  }

  return numberValidator(value, range)
}
