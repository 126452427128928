<script lang="ts" setup>
import { CfgTable, CfgTypography, CfgTypographySizes, TableCallAlign } from '@cfgtech/ui'
import { mapValues, omit } from 'lodash-es'
import type { FileList } from '../../../../../../admin/types/components/FileList'
import type { IssueFileCategories } from '../../../../../../admin/types/components/IssueFileCategories'
import { DocumentAlertState, type DocumentsTable, type DocumentsTableItem, type State } from './Documents.types'
import type { Company, CompanyFileCategories, FileCategory, FileListItem, Issue } from '~/api/types'
import type { IssueBuyLocations } from '~/components/Issue/types'
import type { Collateral } from '~~/../admin/types/components/Collateral'

const props = defineProps<{
  id: string
  issue: Issue
  isDataAvailable: boolean
}>()

defineEmits<{
  'open:sidesheet': []
  'close:sidesheet': []
  'buy:issue': [location: IssueBuyLocations]
}>()

const DOCUMENTS_COUNT_IN_PAGE = 2

const { t } = useI18n()
const responsive = useResponsive()

type AllDocumentsKeys = keyof Omit<IssueFileCategories, 'id'> | keyof Omit<CompanyFileCategories, 'id'>

const documentsOrder = {
  issueTermSheet: 1,
  amendmentToTermSheet: 2,
  collateralInstruments: 3,
  incomeStatement: 4,
  balanceSheet: 5,
  baseProspectus: 6,
  prospectusAmendment: 7,
  auditReport: 8,
  annualReport: 9,
  financialStatement: 10,
  cashFlowStatement: 11,
  otherDocuments: 12,
} satisfies Record<AllDocumentsKeys, number>

const documentsFromCollaterals = computed(() => ({
  files: props.issue.collaterals?.flatMap((collateral: Collateral) =>
    collateral.files?.map(file => ({
      ...file,
      customFilename: file.customFilename || file.file.data.attributes.name,
    })),
  ) || [],
}))

const issueDocumentsCategories = computed(() => props.issue?.fileCategories ? omit(props.issue.fileCategories, ['id', 'collateralInstruments']) : {})
const companyDocumentsCategories = computed(() => props.issue?.company?.data?.attributes?.fileCategories ? omit(props.issue.company.data.attributes.fileCategories, 'id') : {})

const baseHead = computed(() => [
  { keyName: 'name', text: '', width: responsive.value.desktop !== false ? '50%' : '120px' },
  { keyName: 'year', text: '', width: responsive.value.desktop !== false ? '40%' : '40px' },
  { keyName: 'url', text: '', align: TableCallAlign.right, width: responsive.value.desktop !== false ? '10%' : '120px' },
] satisfies DocumentsTable['headers'])

const allDocuments = computed<Record<AllDocumentsKeys, FileCategory | undefined>>(() => ({
  ...issueDocumentsCategories.value,
  ...companyDocumentsCategories.value,

  otherDocuments: {
    files: [
      ...(issueDocumentsCategories.value?.otherDocuments?.files || []),
      ...(companyDocumentsCategories.value?.otherDocuments?.files || []),
    ],
  },

  collateralInstruments: documentsFromCollaterals.value,
}))

const sortedDocuments = computed(() => Object.entries(documentsOrder)
  .sort(([, a], [, b]) => a - b)
  .reduce((acc, [key]) => {
    acc[key as AllDocumentsKeys] = allDocuments.value[key as AllDocumentsKeys]
    return acc
  }, {} as Record<AllDocumentsKeys, FileCategory | undefined>),
)

const documentsTables = computed(() =>
  Object.values(
    mapValues(
      sortedDocuments.value,
      (value, key) => ({
        title: t(`issue.fileCategories.${key}`) as string, // TODO: Probably bug in i18n, because it returns any instead of string
        table: buildDocumentsTable(value?.files || []),
        alert: props.issue?.company && (key === 'balanceSheet' || key === 'incomeStatement')
          ? useDocumentAlert(key as AllDocumentsKeys, props.issue?.company)
          : null,
      } satisfies DocumentsTableItem),
    ),
  ).filter(({ table, alert }) => table.data.length || alert),
)

/**
 * The First two categories will be shown in the page, others will be shown in the modal
 */
const tableInPage = computed(() => documentsTables.value.filter(documentsTable => documentsTable.table.data.length).slice(0, DOCUMENTS_COUNT_IN_PAGE))

const leftDocumentsCount = computed(() => calculateDocumentsInTables(documentsTables.value) - calculateDocumentsInTables(tableInPage.value))

function calculateDocumentsInTables(tables: DocumentsTableItem[]) {
  return tables.reduce((partialSum, documentsTable) => partialSum + documentsTable.table.data.length, 0)
}

function buildDocumentsTable(files: FileListItem[]): DocumentsTable {
  return {
    headers: baseHead.value,
    data: files
      .filter(({ file }) => file?.data)
      .map(({ file, year, customFilename, filename }) => ({
        name: customFilename || filename || '',
        year,
        url: file.data.attributes.url,
      })),
  }
}

function useDocumentAlert(category: AllDocumentsKeys, company: Company): State | null {
  if (category !== 'balanceSheet' && category !== 'incomeStatement') {
    throw new Error('Category not supported. You need to add the category to the translations and maybe implement custom logic.')
  }

  if (!company?.data?.attributes?.identification) {
    return null // Hide alert if the company does not exist
  }

  const companyName = company.data.attributes.name
  const currentYear = new Date(Date.now()).getFullYear()
  const companyCreateYear = company.data.attributes.identification?.startDate
    ? new Date(company.data.attributes.identification?.startDate).getFullYear()
    : null
  const yearsAfterCompanyCreate = companyCreateYear ? currentYear - companyCreateYear : null

  if (yearsAfterCompanyCreate === null || yearsAfterCompanyCreate === 0) {
    return null
  }

  if (yearsAfterCompanyCreate === 1) {
    return {
      state: DocumentAlertState.Warning,
      text: t(`issue.documents.alerts.${category}.notRequired`, {
        year: yearsAfterCompanyCreate - 1,
        companyName,
      }),
    }
  }

  const requiredYears: Array<{
    year: number
    document: FileListItem | undefined
  }> = []

  for (let i = 0; i < yearsAfterCompanyCreate! - 1; i++) {
    const year = companyCreateYear! + i
    const document = allDocuments.value?.[category]?.files?.find?.((document: FileList) => document.year === year)

    requiredYears.push({
      year,
      document: document as FileListItem | undefined,
    })
  }

  const missingRequiredDocuments = requiredYears.filter(({ document }) => !document).sort((a, b) => a.year - b.year)

  // All required documents are passed
  if (missingRequiredDocuments.length === 0) {
    const isOptionalDocumentPassed = allDocuments.value?.[category]?.files?.find?.((document: FileList) => document.year === companyCreateYear! - 1)

    // Optional document is not passed
    if (!isOptionalDocumentPassed) {
      return {
        state: DocumentAlertState.Warning,
        text: t(`issue.documents.alerts.${category}.missingDocumentFromPastYear`, {
          year: currentYear - 1,
          companyName,
        }),
      }
    }

    return null // All documents are passed (required and optional)
  }

  // Specify the translation for one missing document
  if (missingRequiredDocuments.length === 1) {
    return {
      state: DocumentAlertState.Error,
      text: t(`issue.documents.alerts.${category}.missingOneRequiredDocument`, {
        year: missingRequiredDocuments[0].year,
        companyName,
      }),
    }
  }

  // Check if missing years are consecutive
  const areMissingYearsConsecutive = missingRequiredDocuments.every(({ year }, index, array) => {
    if (index === 0) {
      return true
    }

    return year === array[index - 1].year + 1
  })

  if (
    areMissingYearsConsecutive
    && missingRequiredDocuments[missingRequiredDocuments.length - 1].year === currentYear - 2 // Last missing year is the year before the company was created (1st required year)
  ) {
    return {
      state: DocumentAlertState.Error,
      text: t(`issue.documents.alerts.${category}.missingRequiredConsecutive`, {
        year: Math.min(...missingRequiredDocuments.map(({ year }) => year)),
        companyName,
      }),
    }
  }

  return {
    state: DocumentAlertState.Error,
    text: t(`issue.documents.alerts.${category}.missingRequired`, {
      years: missingRequiredDocuments.map(({ year }) => year).join(', '),
      companyName,
    }),
  }
}
</script>

<template>
  <BaseSectionBox
    :id="id"
    :button="leftDocumentsCount ? {
      label: $t('issue.documents.showMore.label', { count: calculateDocumentsInTables(documentsTables) }),
    } : undefined"
    :title="$t('issue.documents.title')"
    @buy:issue="$emit('buy:issue', $event)"
    @close:sidesheet="$emit('close:sidesheet')"
    @open:sidesheet="$emit('open:sidesheet')"
  >
    <!--  TABLES IN PAGE  -->

    <div v-if="isDataAvailable">
      <ul class="space-y-5">
        <li v-for="{ title, table } in tableInPage" :key="title">
          <CfgTypography
            class="!font-highlighted"
            :size="CfgTypographySizes.lg"
            tag-name="h3"
          >
            {{ title }}
          </CfgTypography>

          <CfgTable
            v-if="table.data.length"
            compact
            :data-sources="table"
            fixed
            flat-firts-cell
          >
            <!--  FILENAME  -->
            <template #body:name="{ value, item: { url } }">
              <a
                class="break-all text-brand underline"
                download
                :href="url"
                target="_blank"
              >
                {{ value }}
              </a>
            </template>

            <!--  YEAR -->

            <template #body:year="{ value }">
              <span class="pl-4 md:pl-6">
                {{ value || '-' }}
              </span>
            </template>

            <!--  DOWNLOAD BUTTON  -->

            <template #body:url="{ item: { url } }">
              <a
                class="text-brand underline"
                download
                :href="url"
                target="_blank"
              >
                {{ $t('common.download') }}
              </a>
            </template>
          </CfgTable>

          <CfgTypography
            v-if="!table.data.length"
            :size="CfgTypographySizes.md"
            tag-name="p"
          >
            {{ $t('issue.documents.notPassed') }}
          </CfgTypography>
        </li>
      </ul>
    </div>

    <div v-else>
      <CfgTypography :size="CfgTypographySizes.md" tag-name="p">
        {{ $t('issue.documents.missingData.title') }}
      </CfgTypography>
    </div>

    <template v-if="leftDocumentsCount" #sidesheet:content>
      <ul class="space-y-5">
        <li v-for="{ title, table, alert } in documentsTables" :key="title">
          <CfgTypography
            class="!font-highlighted"
            :size="CfgTypographySizes.lg"
            tag-name="h3"
          >
            {{ title }}
          </CfgTypography>

          <CfgTable
            v-if="table.data.length"
            compact
            :data-sources="table"
            flat-firts-cell
          >
            <!--  FILENAME  -->
            <template #body:name="{ value, item: { url } }">
              <a
                class="break-all text-brand underline"
                download
                :href="url"
                target="_blank"
              >
                {{ value }}
              </a>
            </template>

            <!--  YEAR -->

            <template #body:year="{ value }">
              <span class="pl-4 md:pl-6">
                {{ value || '-' }}
              </span>
            </template>

            <!--  DOWNLOAD BUTTON  -->

            <template #body:url="{ item: { url } }">
              <a
                class="text-brand underline"
                download
                :href="url"
                target="_blank"
              >
                {{ $t('common.download') }}
              </a>
            </template>
          </CfgTable>

          <BaseAlert
            v-if="alert"
            class="mt-3"
            :variant="alert.state === DocumentAlertState.Error ? 'warning' : 'info'"
          >
            <CfgTypography :size="CfgTypographySizes.md">
              {{ alert.text }}
            </CfgTypography>
          </BaseAlert>
        </li>
      </ul>
    </template>
  </BaseSectionBox>
</template>
