import { paths } from '~/constants/paths.js'

export function usePreviousRoute() {
  const previousRoute = computed(() => {
    const prevRoute = useRouter().options.history.state.back
    if (prevRoute && typeof prevRoute === 'string') {
      if (prevRoute.includes(paths.corporateIssues))
        return prevRoute
    }

    return paths.corporateIssues
  })

  return previousRoute
}
