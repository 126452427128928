<script setup lang="ts">
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import dayjs from 'dayjs'
import { APP_DATE_FORMAT } from '~/constants/common'

const props = defineProps<{
  mfcrScoreChangeDate?: string | Date
  mfcrScoreYear?: string | number
  textClasses?: string
}>()

const mfcrScoreChangeDateFormatted = computed(() => (props.mfcrScoreChangeDate
  ? dayjs(props.mfcrScoreChangeDate).format(APP_DATE_FORMAT)
  : null))
</script>

<template>
  <CfgTypography
    v-if="mfcrScoreChangeDateFormatted"
    :class="textClasses"
    :size="CfgTypographySizes.sm"
    tag-name="div"
  >
    {{ $t('issue.mfcrScoreChangeDate', { date: mfcrScoreChangeDateFormatted }) }} {{ mfcrScoreYear ? $t('issue.mfcrScoreChangeYear', { year: mfcrScoreYear }) : '.' }}
  </CfgTypography>
</template>
