<script lang="ts" setup>
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import type { IssueDetails } from '~/api/modules/issues/services/getIssueDetails/getIssueDetails.types'

const props = defineProps<{
  issue: IssueDetails
  id: string
}>()

const issue = toRef(props, 'issue')

const investmentExampleText = useInvestmentExample(issue)
</script>

<template>
  <BaseSectionBox :id="id" :title="$t('issue.investmentExample.title')">
    <CfgTypography :size="CfgTypographySizes.md" tag-name="div">
      <TypographyStyledText>
        <CoreMarkdownRenderer :markdown="investmentExampleText" />
      </TypographyStyledText>
    </CfgTypography>
  </BaseSectionBox>

  <IssueDetailsTax asterisk class="mt-5 block" />
</template>
