import dayjs from 'dayjs'
import type { IssueWarning } from './types/types'
import { APP_DATE_FORMAT } from '~/constants/common'
import type { Company } from '~/api/types'

export type useUnreliableVatOutput = Omit<Company['lustration']['unreliableVat'], 'lastCheck'> & { show: boolean, lastCheck?: string, lustration: IssueWarning }

export function useUnreliableVat(company?: Company): useUnreliableVatOutput | null {
  if (!company?.lustration?.unreliableVat) {
    return null
  }

  const { unreliableVat } = company.lustration

  const { t } = useI18n()
  const lastCheck = unreliableVat.lastCheck ? dayjs(unreliableVat.lastCheck).format(APP_DATE_FORMAT) : undefined

  return {
    ...unreliableVat,
    show: Boolean(unreliableVat.unreliableVatActive === '1'),
    lastCheck,
    lustration: {
      title: t('issue.lustration.unreliableVat.title', { companyName: company.name || '' }),
      tooltip: t('issue.lustration.unreliableVat.tooltip') + (lastCheck || ''),
    },
  }
}
