import type { ComputedRef, Ref } from 'vue'
import type { IssueDetails } from '~/api/modules/issues/services/getIssueDetails/getIssueDetails.types'
import {
  IssuesInvestorType,
  IssuesReturnPayoutProbabilities,
  IssuesRisks,
} from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import type { UpdatedIssue } from '~/components/Issue/Table/types'

type IssueMfcrScore = {
  risk: ComputedRef<IssuesRisks | null>
  investorType: ComputedRef<IssuesInvestorType | null>
  payoutProbability: ComputedRef<IssuesReturnPayoutProbabilities | null>
}

function getIssueRisk(score: number) {
  if (score >= 0 && score <= 3.5) {
    return IssuesRisks.Highest
  }
  if (score >= 4 && score <= 7) {
    return IssuesRisks.High
  }
  if (score >= 7.5 && score <= 11) {
    return IssuesRisks.Medium
  }
  if (score >= 11.5 && score <= 15) {
    return IssuesRisks.Low
  }
  if (score >= 15.5 && score <= 19) {
    return IssuesRisks.Lowest
  }
  return null
}

export function getIssueInvestorType(score: number) {
  if (score >= 0 && score <= 3.5) {
    return IssuesInvestorType.Aggresive
  }
  if (score >= 4 && score <= 7) {
    return IssuesInvestorType.Dynamic
  }
  if (score >= 7.5 && score <= 11) {
    return IssuesInvestorType.Balanced
  }
  if (score >= 11.5 && score <= 15) {
    return IssuesInvestorType.Conservative
  }
  if (score >= 15.5 && score <= 19) {
    return IssuesInvestorType.Defensive
  }
  return null
}

export function getIssuePayoutProbability(score: number) {
  if (score >= 0 && score <= 3.5) {
    return IssuesReturnPayoutProbabilities.VeryLow
  }
  if (score >= 4 && score <= 7) {
    return IssuesReturnPayoutProbabilities.Low
  }
  if (score >= 7.5 && score <= 11) {
    return IssuesReturnPayoutProbabilities.MediumHigh
  }
  if (score >= 11.5 && score <= 15) {
    return IssuesReturnPayoutProbabilities.QuiteHigh
  }
  if (score >= 15.5 && score <= 19) {
    return IssuesReturnPayoutProbabilities.VeryHigh
  }
  return null
}

export function useMfcrScoreFromIssueDetail(issue: Ref<IssueDetails | null>): IssueMfcrScore {
  const mfcrScore = issue.value?.scoreData?.score

  return {
    risk: computed(() => (mfcrScore !== undefined
      ? getIssueRisk(mfcrScore)
      : null)),
    investorType: computed(() => (mfcrScore !== undefined
      ? getIssueInvestorType(mfcrScore)
      : null)),
    payoutProbability: computed(() => (mfcrScore !== undefined
      ? getIssuePayoutProbability(mfcrScore)
      : null)),
  }
}

export function useMfcrScoreFromIssue(issue: UpdatedIssue): IssueMfcrScore {
  const mfcrScore = issue.scoreData?.score

  return {
    risk: computed(() => (mfcrScore !== undefined
      ? getIssueRisk(mfcrScore)
      : null)),
    investorType: computed(() => (mfcrScore !== undefined
      ? getIssueInvestorType(mfcrScore)
      : null)),
    payoutProbability: computed(() => (mfcrScore !== undefined
      ? getIssuePayoutProbability(mfcrScore)
      : null)),
  }
}
