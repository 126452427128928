import dayjs from 'dayjs'
import type { IssueWarning } from './types/types'
import type { Company } from '@/api/types'
import { APP_DATE_FORMAT } from '~/constants/common'

export type useInsolvencyOutput = Omit<Company['lustration']['insolvency'], 'lastCheck'> & { show: boolean, lastCheck?: string, lustration: IssueWarning }

export function useInsolvency(company?: Company): useInsolvencyOutput | null {
  const lustrations = company?.lustration

  if (!lustrations?.insolvency) {
    return null
  }

  const { t } = useI18n()

  const { insolvency } = lustrations

  const lastCheck = insolvency.lastCheck ? dayjs(insolvency.lastCheck).format(APP_DATE_FORMAT) : undefined

  return {
    ...insolvency,
    show: Boolean(insolvency?.hasInsolvency && insolvency?.isPublished),
    lastCheck,
    lustration: {
      title: t('issue.lustration.insolvency.title', { companyName: company!.name || '' }),
      tooltip: t('issue.lustration.insolvency.tooltip') + (lastCheck || ''),
      url: insolvency.url,
    },
  }
}
