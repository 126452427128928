<script setup lang="ts">
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import type { Issue } from '~/api/types'
import type { IssueCompletenessSections } from '~/composables/issues/useIssueCompleteness'

const props = defineProps<{
  issue: Issue
  completenessPercentage: number
  completenessSummary: Record<IssueCompletenessSections, boolean>
}>()

const linkToCompany = computed(() => {
  if (props.issue.company?.data?.attributes?.slug) {
    return `/emitenti/dluhopisy-${props.issue.company.data.attributes.slug}`
  }

  return ''
})
</script>

<template>
  <div>
    <CfgTypography
      class="flex flex-col"
      data-cy="issue-title"
      :size="CfgTypographySizes.h1"
      tag-name="h1"
    >
      <span data-cy="issue-detail-title" v-text="$t('issue.issueCaption', { issueName: issue.name })" />

      <CfgTypography
        v-if="issue.company?.data?.attributes?.name"
        class="mt-1 flex flex-wrap items-center gap-x-6 gap-y-2 md:mt-2"
        data-cy="issue-detail-issuer-name"
        :size="CfgTypographySizes.sm"
      >
        <span class="flex gap-x-1">
          {{ $t('issue.issueCompany') }}

          <NuxtLink
            v-if="linkToCompany"
            class="text-brand underline"
            :to="linkToCompany"
          >
            {{ issue.company.data.attributes.name || '' }}
          </NuxtLink>

          <span v-else> {{ issue.company.data.attributes.name || '' }}</span>
        </span>

        <IssueDetailsV2CompletenessChip
          :completeness-percentage="completenessPercentage"
          hint-on-hover
          :summary="completenessSummary"
        />
      </CfgTypography>
    </CfgTypography>
  </div>
</template>
