<script lang="ts" setup>
import { CfgTable, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import type { Company, Issue } from '~/api/types'
import { AvailableImageWidth } from '~/components/base/Picture/types'
import { transformStrapiDeep } from '~/api/helpers/functions'
import type { SectionBoxExposed } from '~/components/Issue/Details/v2/SectionBox/SectionBox.types'
import type { IssueBuyLocations } from '~/components/Issue/types'
import { IS_OWERNSHIP_SUBSIDIARY, IS_OWNERSHIP_MATERNAL } from '~/constants/company'

const props = defineProps<{
  id: string
  issue: Issue
}>()

const emit = defineEmits<{
  'getIssuerContact': []
  'open:sidesheet': []
  'close:sidesheet': []
  'buy:issue': [location: IssueBuyLocations]
}>()

const sectionBoxRef = ref<SectionBoxExposed | null>(null)
const isContactIssuerDialogOpened = ref(false)

const extendedDetail = computed(() => props.issue.company?.data?.attributes?.extendedDetail)
const company = computed(() => props.issue.company?.data?.attributes)

const {
  companyBaseInfo,
  companyContactTable,
  companyStatutoryTable,
  buildOwnershipsTable,
} = useCompanyContent()

const companyBaseInfoDataSource = companyBaseInfo(company?.value as Company)
const companyContactTableDataSource = companyContactTable(company?.value as Company, isContactIssuerDialogOpened)
const companyStatutoryTableDataSource = companyStatutoryTable(company?.value as Company)

const ownerships = computed(() => company.value?.ownerships || [])

// Shown only ownerships with a known relationship type and one level of ownership (1, -1)
const maternalsOwnerships = useArrayFilter(ownerships, ({ relationshipType }) => relationshipType ? relationshipType === IS_OWNERSHIP_MATERNAL : false)
const subsidiaryOwnerships = useArrayFilter(ownerships, ({ relationshipType }) => relationshipType ? relationshipType === IS_OWERNSHIP_SUBSIDIARY : false)

const maternalOwnershipsTable = computed(() => buildOwnershipsTable(maternalsOwnerships.value))
const subsidiaryOwnershipsTable = computed(() => buildOwnershipsTable(subsidiaryOwnerships.value))

watch(() => isContactIssuerDialogOpened, () => {
  emit('getIssuerContact')
}, { deep: true })
</script>

<template>
  <BaseSectionBox
    :id="id"
    ref="sectionBoxRef"
    :button="{
      label: $t('issue.companyDetails.button.label'),
    }"
    :caption="$t('issue.issueName.title', { issueName: issue.name })"
    :title="$t('issue.companyDetails.title', { companyName: issue.company?.data?.attributes?.name || '' })"
    @buy:issue="$emit('buy:issue', $event)"
    @close:sidesheet="emit('close:sidesheet')"
    @open:sidesheet="emit('open:sidesheet')"
  >
    <!--  COMPANY EXTENDED DETAIL SHORT DESCRIPTION  -->
    <TypographyStyledText v-if="extendedDetail?.shortDescription">
      <CoreMarkdownRenderer class="font-display text-grey-400" :markdown="extendedDetail.shortDescription" />
    </TypographyStyledText>

    <!--  COMPANY IDENTIFICATION TABLE  -->
    <CfgTable
      compact
      :data-sources="companyBaseInfoDataSource"
      fixed
      flat-firts-cell
    >
      <template #body:key="{ item: { key } }">
        <CfgTypography class="max-w-full text-grey-300" :size="CfgTypographySizes.sm">
          {{ key }}
        </CfgTypography>
      </template>
    </CfgTable>

    <!--  SIDESHEET  -->

    <template #sidesheet:content>
      <!--  SIDESHEET: COMPANY EXTENDED DETAIL DESCRIPTION  -->
      <div>
        <TypographyStyledText v-if="extendedDetail?.description">
          <CoreMarkdownRenderer class="font-display  text-grey-400" :markdown="extendedDetail.description" />
        </TypographyStyledText>
      </div>

      <!--  SIDESHEET: COMPANY EXTENDED DETAIL VIDEO  -->

      <div v-if="extendedDetail?.youtubeUrl" class="mt-5 aspect-video">
        <iframe
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class=" w-full rounded-xl"
          frameborder="0"
          loading="lazy"
          :src="extendedDetail.youtubeUrl"
        />
      </div>

      <!--  SIDESHEET: COMPANY EXTENDED DETAIL IMAGE  -->

      <ClientOnly v-else-if="extendedDetail?.image?.data">
        <div class="mt-5 aspect-video">
          <div class="absolute left-0 top-0 size-full">
            <LazyBasePicture
              class="block size-full"
              :image-attributes="{
                class: 'w-full h-full object-cover object-left-top rounded-lg',
              }"
              loading="lazy"
              :sizes="[
                { screen: 500, width: AvailableImageWidth.small },
              ]"
              :src="transformStrapiDeep(extendedDetail?.image)?.url"
              use-gallery
            />
          </div>
        </div>
      </ClientOnly>

      <section class="mt-9 space-y-9">
        <!--  SIDESHEET: COMPANY IDENTIFICATION TABLE  -->
        <div>
          <div class="pb-1">
            <CfgTypography
              class="block !font-highlighted"
              :size="CfgTypographySizes.lg"
              tag-name="h3"
            >
              {{ $t('company.identification.title') }}
            </CfgTypography>
          </div>

          <CfgTable
            compact
            :data-sources="companyBaseInfoDataSource"
            fixed
            flat-firts-cell
          >
            <template #body:key="{ item: { key } }">
              <CfgTypography class="max-w-full text-grey-300" :size="CfgTypographySizes.sm">
                {{ key }}
              </CfgTypography>
            </template>
          </CfgTable>
        </div>

        <!--  SIDESHEET: COMPANY CONTACT TABLE  -->

        <div v-if="company?.contact">
          <div class="pb-1">
            <CfgTypography
              class="block !font-highlighted"
              :size="CfgTypographySizes.lg"
              tag-name="h3"
            >
              {{ $t('company.contact.info') }}
            </CfgTypography>
          </div>

          <CfgTable
            compact
            :data-sources="companyContactTableDataSource"
            fixed
            flat-firts-cell
            @get-issuer-contact.stop="$emit('getIssuerContact')"
          >
            <template #body:key="{ item: { key } }">
              <CfgTypography class="max-w-full text-grey-300" :size="CfgTypographySizes.sm">
                {{ key }}
              </CfgTypography>
            </template>

            <template #body:value="{ item: { value, meta } }">
              <CfgTypography
                class="max-w-full"
                :size="CfgTypographySizes.sm"
                @click.prevent="meta?.onClick && meta.onClick()"
              >
                <span v-html="value" />
              </CfgTypography>
            </template>
          </CfgTable>
        </div>

        <!--  SIDESHEET: COMPANY STATUTORY TABLE  -->

        <div v-if="company?.statutory">
          <div class="pb-1">
            <CfgTypography
              class="block !font-highlighted"
              :size="CfgTypographySizes.lg"
              tag-name="h3"
            >
              {{ $t('company.statutory.title') }}
            </CfgTypography>
          </div>

          <CfgTable
            compact
            :data-sources="companyStatutoryTableDataSource"
            fixed
            flat-firts-cell
          >
            <template #body:key="{ item: { key } }">
              <CfgTypography class="max-w-full text-grey-300" :size="CfgTypographySizes.sm">
                {{ key }}
              </CfgTypography>
            </template>

            <template #body:value="{ item: { value: { name, startDate } } }">
              <span>
                {{ name }}

                <span v-if="startDate" class="block">
                  {{ startDate }}
                </span>
              </span>
            </template>
          </CfgTable>
        </div>

        <!--  SIDESHEET: MATERNAL OWNERSHIPS TABLE  -->

        <div>
          <div class="space-y-3 pb-1">
            <CfgTypography
              class="block !font-highlighted"
              :size="CfgTypographySizes.lg"
              tag-name="h3"
            >
              {{ $t('company.ownerships.maternal.title') }}
            </CfgTypography>

            <CfgTypography
              class="block"
              :size="CfgTypographySizes.sm"
              tag-name="p"
            >
              {{ $t('company.ownerships.maternal.description', { companyName: company?.name || '' }) }}
            </CfgTypography>
          </div>

          <CfgTable
            v-if="maternalOwnershipsTable.data.length"
            compact
            :data-sources="maternalOwnershipsTable"
            fixed
            flat-firts-cell
          >
            <template #body:key="{ item: { key } }">
              <CfgTypography class="max-w-full text-grey-300" :size="CfgTypographySizes.sm">
                {{ key }}
              </CfgTypography>
            </template>

            <template #body:value="{ item: { value: { commonInformation, valuePercent, startDate, endDate } } }">
              <CfgTypography
                class="max-w-full"
                :size="CfgTypographySizes.sm"
              >
                <span v-html="commonInformation" />

                <span class="block" v-html="valuePercent" />

                <span v-if="startDate" class="block">
                  {{ startDate }}
                </span>

                <span v-if="endDate" class="block">
                  {{ endDate }}
                </span>
              </CfgTypography>
            </template>
          </CfgTable>

          <CfgTypography
            v-else
            class="mt-2.5 border-t border-t-grey-stroke py-2"
            :size="CfgTypographySizes.sm"
            tag-name="p"
          >
            {{ $t('company.ownerships.maternal.empty.title', { companyName: company?.name || '' }) }}
          </CfgTypography>
        </div>

        <!--  SIDESHEET: SUBSIDIARY OWNERSHIPS TABLE  -->

        <div>
          <div class="space-y-3 pb-1">
            <CfgTypography
              class="block !font-highlighted"
              :size="CfgTypographySizes.lg"
              tag-name="h3"
            >
              {{ $t('company.ownerships.subsidiary.title') }}
            </CfgTypography>

            <CfgTypography
              class="block"
              :size="CfgTypographySizes.sm"
              tag-name="p"
            >
              {{ $t('company.ownerships.subsidiary.description', { companyName: company?.name || '' }) }}
            </CfgTypography>
          </div>

          <CfgTable
            v-if="subsidiaryOwnershipsTable.data.length"
            compact
            :data-sources="subsidiaryOwnershipsTable"
            fixed
            flat-firts-cell
          >
            <template #body:key="{ item: { key } }">
              <CfgTypography class="max-w-full text-grey-300" :size="CfgTypographySizes.sm">
                {{ key }}
              </CfgTypography>
            </template>

            <template #body:value="{ item: { value: { commonInformation, valuePercent, startDate, endDate } } }">
              <CfgTypography class="max-w-full" :size="CfgTypographySizes.sm">
                <span v-html="commonInformation" />

                <span class="block" v-html="valuePercent" />

                <span v-if="startDate" class="block">
                  {{ startDate }}
                </span>

                <span v-if="endDate" class="block">
                  {{ endDate }}
                </span>
              </CfgTypography>
            </template>
          </CfgTable>

          <CfgTypography
            v-else
            class="mt-2.5 border-t border-t-grey-stroke py-2"
            :size="CfgTypographySizes.sm"
            tag-name="p"
          >
            {{ $t('company.ownerships.subsidiary.empty.title', { companyName: company?.name || '' }) }}
          </CfgTypography>
        </div>
      </section>
    </template>
  </BaseSectionBox>
</template>
