import type { Ref } from 'vue'
import dayjs from 'dayjs'
import type { IssueDetails } from '~/api/modules/issues/services/getIssueDetails/getIssueDetails.types'
import useBooleanTranslator from '~/composables/useBooleanTranslator'
import { APP_DATE_FORMAT } from '~/constants/common'
import { ReasonState } from '~/constants/issue'
import { useMoneyFormat } from '~/composables/useMoneyFormat'

export function useIssuesDetails(
  issue: Ref<IssueDetails | null>,
  t: ReturnType<typeof useI18n>['t'],
) {
  const { withDefaultValue } = useWithDefaultValue(t('common.unavailable'))

  const totalAmount = computed(() => issue.value?.totalAmount ? useMoneyFormat(issue.value.totalAmount, true) : null)

  const booleanTranslator = useBooleanTranslator(t)
  // Todo: we cannot use computed outside of script setup. Pls use useState instead;
  // Todo: use switch case instead of if else
  const buyAvailableStatusText = computed<ReasonState>(() => {
    // eslint-disable-next-line unicorn/prefer-includes
    if (!issue.value || [issue.value.availableCount, issue.value.isAvailable, issue.value.maturityDate].some(value => value === null)) {
      return ReasonState.unknown
    }

    if (
      issue.value.maturityDate
      && (new Date()).getTime() - (new Date(issue.value.maturityDate)).getTime() > 0
    ) {
      return ReasonState.afterMaturityDate
    }

    if (!issue.value.availableCount) { // null or 0
      return ReasonState.soldOut
    }

    if (!issue.value.isAvailable) { // null or false
      return ReasonState.unavailable
    }

    return ReasonState.availableForBay
  })

  return {
    buyAvailableStatusText,

    parameters: [
      {
        title: t('issue.isin.title'),
        value: withDefaultValue(issue.value?.isin),
        tooltip: t('issue.isin.tooltip'),
      },

      {
        title: issue.value?.issueDate ? t('issue.issueDate.title') : t('issue.registrationDate.title'),
        value: issue.value?.issueDate
          ? dayjs(issue.value?.issueDate).format(APP_DATE_FORMAT)
          : (issue.value?.registrationDate
              ? dayjs(issue.value?.registrationDate).format(APP_DATE_FORMAT)
              : withDefaultValue()),
        tooltip: issue.value?.issueDate ? t('issue.issueDate.tooltip') : t('issue.registrationDate.tooltip'),
      },

      {
        title: t('issue.totalAmount.title'),
        value: `${withDefaultValue(totalAmount.value)} ${totalAmount.value !== null ? t(`common.currencies.${issue.value?.currency}`) : ''}`,
        tooltip: t('issue.totalAmount.tooltip'),
      },

      {
        title: t('issue.returnsPeriodicity.title'),
        value: withDefaultValue(issue.value?.returnsPeriodicity),
        tooltip: t('issue.returnsPeriodicity.tooltip'),
      },

      {
        title: t('issue.hasEarlyRepayment.title'),
        value: booleanTranslator.yesNoNull(issue.value?.hasEarlyRepayment),
        tooltip: t('issue.hasEarlyRepayment.tooltip'),
      },

      {
        title: t('issue.purpose.title'),
        value: withDefaultValue(issue.value?.purpose),
        tooltip: t('issue.purpose.tooltip'),
      },

      {
        title: t('issue.prospectusApprovedByCNB.title'),
        value: issue.value?.scoreData?.prospectusApprovedByIssuerAuthority !== undefined
          ? t(`common.${issue.value?.scoreData?.prospectusApprovedByIssuerAuthority}`)
          : withDefaultValue(),
        tooltip: t('issue.prospectusApprovedByCNB.tooltip'),
      },

      {
        title: t('issue.issueType.title'),
        value: withDefaultValue(issue.value?.type),
        tooltip: t('issue.issueType.tooltip'),
      },

      {
        title: t('issue.yieldType.title'),
        value: withDefaultValue(issue.value?.interestRate?.yieldType),
        tooltip: t('issue.yieldType.tooltip'),
      },

      {
        title: t('issue.bondResemblance.title'),
        value: withDefaultValue(issue.value?.bondResemblance),
        tooltip: t('issue.bondResemblance.tooltip'),
      },

      {
        title: t('issue.bondForm.title'),
        value: withDefaultValue(issue.value?.bondForm),
        tooltip: t('issue.bondForm.tooltip'),
      },

      {
        title: t('issue.ratingAgency.title'),
        value: withDefaultValue(issue.value?.ratingAgency),
        tooltip: t('issue.ratingAgency.tooltip'),
      },

      {
        title: t('issue.underwritingAgent.title'),
        value: withDefaultValue(issue.value?.underwritingAgent),
      },
    ],
  }
}
