<script setup lang="ts">
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import type { IssueDetails } from '~/api/modules/issues/services/getIssueDetails/getIssueDetails.types'
import { IssuesCollateralType } from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import type { IssueBuyLocations } from '~/components/Issue/types'

const props = defineProps<{
  issue: IssueDetails
  id: string
}>()

const emit = defineEmits<{
  'open:contact-issuer': []
  'open:sidesheet': []
  'close:sidesheet': []
  'buy:issue': [location: IssueBuyLocations]
}>()

const issue = toRef(props, 'issue')

const filteredCollaterals = computed(() => props.issue.collaterals?.filter(collateral => collateral.type !== IssuesCollateralType.none && collateral.type))

const hasNoCollaterals = computed(() => props.issue.collaterals?.some(collateral => collateral.type === IssuesCollateralType.none))

function handleOpenContactIssuer(closeFunction: () => void) {
  closeFunction()

  // wait sidesheet close
  setTimeout(() => {
    emit('open:contact-issuer')
  }, 200)
}
</script>

<template>
  <BaseSectionBox
    :id="id"
    :button="filteredCollaterals?.length ? {
      label: $t('issue.collaterals.button.label'),
    } : undefined"
    :caption="issue.name"
    :title="$t('issue.collaterals.title')"
    v-bind="$attrs"
    @buy:issue="$emit('buy:issue', $event)"
    @close:sidesheet="emit('close:sidesheet')"
    @open:sidesheet="emit('open:sidesheet')"
  >
    <div class="flex flex-col gap-y-4">
      <template v-if="filteredCollaterals?.length">
        <CfgTypography :size="CfgTypographySizes.md" tag-name="p">
          {{ $t('issue.collaterals.description') }}
        </CfgTypography>

        <IssueDetailsV2CollateralsContent
          :collaterals="filteredCollaterals"
          :show-details="false"
        />
      </template>

      <div v-else-if="hasNoCollaterals">
        <CfgTypography
          :size="CfgTypographySizes.md"
          tag-name="p"
        >
          {{ $t('issue.collaterals.noData') }}
        </CfgTypography>
      </div>

      <div v-else>
        <CfgTypography
          :size="CfgTypographySizes.md"
          tag-name="p"
        >
          {{ $t('issue.collaterals.missingData.title') }}
        </CfgTypography>
      </div>
    </div>

    <template #sidesheet:content="{ close }">
      <IssueDetailsV2CollateralsContent
        :collaterals="filteredCollaterals"
        :show-details="true"
      />

      <div class="mt-8">
        <LazyContactIssuer
          location="issueDetail"
          @open:contact-issuer="handleOpenContactIssuer(close)"
        />
      </div>
    </template>
  </BaseSectionBox>
</template>
