<script lang="ts" setup>
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import type { Issue } from '~/api/types'

const props = defineProps<{
  supportUrl: string
  issue: Issue
}>()

const TOTAL_LINES = 10

const areDataAvailable = computed(() => props.issue.availableCount !== null && props.issue.totalAmount !== null)

const availableAmount = computed(() => areDataAvailable.value ? props.issue.availableCount! * props.issue.nominalValue! : null)
const investedAmount = computed(() => {
  if (!props.issue.totalAmount || availableAmount.value === null) {
    return null
  }

  if (availableAmount.value === 0) {
    return props.issue.totalAmount
  }

  return props.issue.totalAmount - availableAmount.value
})

const investedPercent = computed(() => areDataAvailable.value ? (investedAmount.value! / props.issue.totalAmount!) : 0)

const filledLines = computed(() => areDataAvailable.value ? Math.ceil((TOTAL_LINES * investedPercent.value)) : 0)

const totalAmountFormatted = computed(() => props.issue.totalAmount ? useMoneyFormat(props.issue.totalAmount, true) : null)

const investedAmountFormatted = computed(() => investedAmount.value !== null ? useMoneyFormat(investedAmount.value, true) : null)
</script>

<template>
  <div>
    <CfgTypography
      class="!font-highlighted text-grey-400"
      :size="CfgTypographySizes.sm"
      tag-name="h2"
    >
      {{
        areDataAvailable
          ? $t('issue.heroSection.chart.title', { percent: Math.floor(investedPercent * 100) })
          : $t('issue.heroSection.chart.notAvailableTitle')
      }}
    </CfgTypography>

    <div
      v-if="!areDataAvailable"
      class="mt-1 block"
    >
      <CfgTypography :size="CfgTypographySizes.md" tag-name="p">
        {{ $t('issue.heroSection.chart.dataUnavailable.title') }}
      </CfgTypography>
    </div>

    <template v-if="areDataAvailable">
      <div class="mt-3 flex gap-x-1 overflow-hidden rounded-full font-highlighted">
        <span
          v-for="line in TOTAL_LINES"
          :key="line"
          class="block h-2 flex-1"
          :class="{
            'bg-brand': line <= filledLines,
            'bg-grey-200': line > filledLines,
          }"
        />
      </div>

      <div class="mt-3 flex justify-between">
        <CfgTypography class="text-dark-100" :size="CfgTypographySizes.xs">
          <template v-if="investedAmount !== null">
            {{ investedAmountFormatted }} {{ $t(`common.currencies.${issue.currency}`) }}
          </template>

          <template v-else>
            {{ $t('common.undefined') }}
          </template>
        </CfgTypography>

        <CfgTypography class="text-grey-500" :size="CfgTypographySizes.xs">
          <template v-if="issue.totalAmount !== null">
            {{ totalAmountFormatted }} {{ $t(`common.currencies.${issue.currency}`) }}
          </template>

          <template v-else>
            {{ $t('common.undefined') }}
          </template>
        </CfgTypography>
      </div>
    </template>
  </div>
</template>
