import type { IssueDetails } from '~/api/modules/issues/services/getIssueDetails/getIssueDetails.types'
import { ISSUE_URL } from '~/constants/issue'
import type { SchemaOrgIssue } from '~/api/modules/issues/services/getSchemaOrgIssues/getSchemaOrgIssues.types'

function makeIssueProduct(issue: SchemaOrgIssue | IssueDetails) {
  const { app: { ORIGIN, baseURL } } = useRuntimeConfig()

  const url = `${ORIGIN}${baseURL}`

  return defineProduct({
    '@id': issue.slug,
    'name': issue?.name,
    'description': `Dluhopis ${issue?.name} ${issue?.company?.data ? `společnosti ${issue?.company?.data?.attributes?.name}` : ''}`,
    'image': '/img/issue-thumb.png',
    'offers': {
      price: issue?.nominalValue,
      priceCurrency: issue?.currency || 'CZK',
      availability: issue?.jamesOrderFormURL ? 'https://schema.org/InStock' : 'https://schema.org/SoldOut',
      url: `${url}${ISSUE_URL}/${issue?.slug}`,
      image: '/img/issue-thumb.png',
    },
    ...(issue?.company?.data
      ? {
          brand: {
            '@type': 'Brand',
            'name': issue?.company?.data?.attributes?.name || '',
          },
        }
      : {}),
  })
}

export function useIssueSchema(issue: IssueDetails): ReturnType<typeof useSchemaOrg> {
  return useSchemaOrg([
    makeIssueProduct(issue),
  ])
}

export function useIssuesListSchema(issues: SchemaOrgIssue[], totalCount: number): ReturnType<typeof useSchemaOrg> {
  useSchemaOrg([
    defineWebSite({
      potentialAction: [
        defineSearchAction({
          target: '/?search={search_term_string}',
        }),
      ],
    }),

    defineWebPage({
      '@type': ['CollectionPage', 'SearchResultsPage'],
    }),

    defineItemList({
      itemListOrder: 'Unordered',
      numberOfItems: totalCount,
      itemListElement: issues.map(makeIssueProduct),
    }),
  ])
}
