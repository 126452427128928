import type { Ref } from 'vue'

export function useContainerMediaQuery(el: Ref<HTMLElement | null>, query: {
  greaterOrEqualThen?: number
  lessThen?: number
}): Ref<boolean> {
  const uniqId = useUniqueId()
  const parentElement = useState<HTMLElement | null>(`mediaQueryRoot-${uniqId}`, () => null)

  const { greaterOrEqualThen, lessThen } = query

  const matches = useState(`mediaQuery-${uniqId}`, () => true)

  watch(el, (el) => {
    parentElement.value = el?.parentNode as HTMLElement || null
  }, { immediate: true })

  useResizeObserver(parentElement, ([{ contentRect: { width } }]) => {
    if (lessThen && greaterOrEqualThen) {
      matches.value = width >= greaterOrEqualThen && width < lessThen
    }

    if (lessThen) {
      matches.value = width < lessThen
    }

    if (greaterOrEqualThen) {
      matches.value = width >= greaterOrEqualThen
    }
  })

  return matches
}
