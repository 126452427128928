<script setup lang="ts">
import type { Translations } from '@cfgtech/ui'
import { CfgButton, CfgInvestCalculator, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import dayjs from 'dayjs'
import type { Issue } from '~/api/types'
import type { IssueBuyLocations } from '~/components/Issue/types'
import { IssueBuyUrlTypeEnum } from '~/components/Issue/types'
import { BASE_FILTERS_URL } from '~/composables/filters/constants'
import { filterKeyToUrlTemplate } from '~/composables/filters/templates'
import { DataLayerLocationsEnum } from '~/composables/useDataLayer'
import { IssuesFiltersKeys, ReturnsPeriodicityEnum } from '~~/stores/issues/types'
import { BOND_AMOUNT } from '~/constants/common'

const props = withDefaults(defineProps<{
  orderUrl: string | null | undefined
  orderUrlType: IssueBuyUrlTypeEnum | null
  issue: Issue
  modelValue: number
}>(), {})

const emit = defineEmits<{
  'update:modelValue': [investmentCount: number]
  'buy:issue': [location: IssueBuyLocations]
}>()

const { t } = useI18n()

const issueStore = useIssuesStore()

const {
  investmentCount,
  maxInvestment,
  minInvestment,
  result,
  issue: calculatorIssue,
} = useCalculator(BOND_AMOUNT, ref(props.issue))

const investmentCountStep = computed(() => props.issue.nominalValue ?? 0)
const showPeriodicResult = computed(() => props.issue.returnsPeriodicity && props.issue.returnsPeriodicity !== ReturnsPeriodicityEnum.OneTime)
const isDataFilled = computed(() => props.issue.maturityDate && props.issue.nominalValue && props.issue.interestRate?.annualInterestRate)

const { orderAvailabilityTranslateKey, orderURL, issue: availabilityIssue } = useIssueOrderAvailability()

availabilityIssue.value = props.issue
calculatorIssue.value = props.issue

const isAfterMaturityDate = computed(() => {
  return props.issue.maturityDate ? dayjs().isAfter(dayjs(props.issue.maturityDate)) : false
})

const showOtherIssuesUrl = `${BASE_FILTERS_URL}${filterKeyToUrlTemplate[IssuesFiltersKeys.CanBeBoughtOnline]}`

const translations = computed<Translations>(() => ({
  title: t('issue.heroSection.calculator.title'),
  label: t('issue.heroSection.calculator.label'),
  currency: t(`common.currencies.${props.issue.currency}`),
  result: {
    periodic: {
      label: t(`issue.heroSection.calculator.results.${props.issue.returnsPeriodicity}.title`),
      tooltip: t(`issue.heroSection.calculator.results.${props.issue.returnsPeriodicity}.tooltip`),
    },
    wholePeriod: {
      label: t('issue.heroSection.calculator.results.whole.title'),
      tooltip: t('issue.heroSection.calculator.results.whole.tooltip'),
    },
  },
}))

const { openContactIssuerForwardDialog } = useContactIssuerForward()

function resetFiltersAndSorts() {
  issueStore.$reset()
}

watch(investmentCount, () => {
  emit('update:modelValue', investmentCount.value)
}, { immediate: true })
</script>

<template>
  <div v-if="isDataFilled && !isAfterMaturityDate">
    <CfgInvestCalculator
      v-model="investmentCount"
      class="!m-0"
      :max-investment="maxInvestment"
      :min-investment="minInvestment"
      :result="!isAfterMaturityDate && isDataFilled ? result : undefined"
      :show-periodic-result="Boolean(showPeriodicResult)"
      :step-value="investmentCountStep"
      :translations="translations"
    >
      <template #periodicResultTooltip="{ closeHint }">
        <span> {{ $t(`issue.heroSection.calculator.results.${issue.returnsPeriodicity}.tooltip`) }} </span>

        <br>

        <br>

        <IssueDetailsTax @click="closeHint" />
      </template>

      <template #wholePeriodTooltip="{ closeHint }">
        <span> {{ $t('issue.heroSection.calculator.results.whole.tooltip') }} </span>

        <br>

        <br>

        <IssueDetailsTax @click="closeHint" />
      </template>
    </CfgInvestCalculator>

    <CfgButton
      v-if="orderURL"
      class="mt-5 w-full py-4 lg:py-2.5"
      data-cy="order-issue-button"
      flat
      :label="$t('issue.heroSection.cta.primary.order')"
      variant="primary"
      @click="$emit('buy:issue', DataLayerLocationsEnum.IssueDetails)"
    >
      {{ orderUrlType === IssueBuyUrlTypeEnum.AFFILIATE ? $t('issue.heroSection.cta.primary.affiliateOrder') : $t('issue.heroSection.cta.primary.order') }}
    </CfgButton>

    <CfgButton
      v-else-if="issue.top"
      as="span"
      class="mt-5 w-full py-2 lg:py-2.5 "
      :label="$t('contact.issuerForwardDialog.button.contact.label')"
      small
      @click="openContactIssuerForwardDialog({ companyName: issue.company?.data.attributes.name || null,
                                               issueName: issue.name,
                                               issueSlug: issue.slug || null,
      })"
    />

    <div v-else class="mt-2 flex flex-col space-y-2 rounded-xl bg-brand-200 p-4">
      <CfgTypography v-if="orderAvailabilityTranslateKey" :size="CfgTypographySizes.md">
        {{ $t(orderAvailabilityTranslateKey) }}
      </CfgTypography>

      <LazyGTMProvider v-slot="{ events, locations }">
        <NuxtLink
          :to="showOtherIssuesUrl"
          @click="events.onShowMoreIssuesClick(locations.IssueDetailsBox); resetFiltersAndSorts()"
        >
          <CfgButton
            as="span"
            class="w-full py-2 lg:py-2.5"
            small
            stroke
          >
            {{ t('issue.heroSection.calculator.showMoreIssue.button.label') }}
          </CfgButton>
        </NuxtLink>
      </LazyGTMProvider>
    </div>
  </div>

  <div v-else class="flex flex-col gap-y-2 rounded-xl bg-brand-light p-4">
    <CfgTypography :size="CfgTypographySizes.md">
      {{ $t('issue.heroSection.calculator.notAvailable.title') }}
    </CfgTypography>

    <LazyGTMProvider v-slot="{ events, locations }">
      <NuxtLink
        :to="showOtherIssuesUrl"
        @click="events.onShowMoreIssuesClick(locations.IssueDetailsBox); resetFiltersAndSorts()"
      >
        <CfgButton
          class="w-full bg-white py-4 lg:py-2.5"
          flat
          icon-placement="left"
          :label="$t('contact.card.ctaFree')"
          stroke
          variant="primary"
        >
          {{ $t('issue.heroSection.calculator.showMoreIssue.button.label') }}
        </CfgButton>
      </NuxtLink>
    </LazyGTMProvider>
  </div>
</template>

<style scoped lang="scss">
#hero-calculator-slider {
  --handle-size: 32px;
  --handle-scale-size: 1.2;
  --line-height: 8px;
}

.button-variant__primary:not(.button-text).button-stroke:before {
  background-color: white;
}
</style>
