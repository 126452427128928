import { v4 as uuidv4 } from 'uuid'
import type { DeepReadonly } from 'vue'
import type { PaginationByPage } from '@nuxtjs/strapi'
import { omit } from 'lodash-es'
import { api } from '~/api/Api'
import type { ApiResponseData } from '~/api/types'
import { SortDirection } from '~/api/types'

import { getBanner } from '~/api/modules/issues/services/getIssuerIssues/getIssuerIssues.service'
import { getIssuesTotalCount } from '~/api/modules/issues/services/getIssuesList/getIssuesList.service'
import type {
  IssueSortKeys,
  ShortIssueWithId,
  getIssuesListServiceInput,
} from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import { removeEqualValues } from '~/helpers/functions'
import { stringifyQuery, transformFullPathToQueryObject } from '~/helpers/queryParser'
import {
  ARCHIVED_ISSUES,
  BANNER_POSITION,
  MAX_HIGHLIGHTED_ISSUES,
  SHOW_ISSUES_PER_PAGE,
  filtersInitialState,
  initialList,
  initialSort,
  issuesFiltersRanges,
} from '~/stores/issues/constants'
import { filterQueryValidator, mapIssues } from '~/stores/issues/helpers'
import type { ActiveSort, ExtraIssuesListFilters, IssueSort, IssuesFiltersKeys, IssuesListFilters, IssuesListsState, IssuesStoreState } from '~/stores/issues/types'
import { IssuesTypes } from '~/stores/issues/types'
import { parseUrlToFilters } from '~/composables/filters/helpers/parseUrlToFilters'
import { buildURLFilterPath } from '~/composables/filters/useFiltersUrls'
import { FILTERS_TO_BE_INCLUDED_IN_PATH } from '~/composables/filters/constants'

const defaultIssues = {
  [IssuesTypes.allIssues]: { ...useCloneDeep(initialList), pagination: { page: 1, pageSize: SHOW_ISSUES_PER_PAGE }, initialCount: SHOW_ISSUES_PER_PAGE },
  [IssuesTypes.onlyOurIssues]: { ...useCloneDeep(initialList), pagination: { page: 1, pageSize: MAX_HIGHLIGHTED_ISSUES }, initialCount: MAX_HIGHLIGHTED_ISSUES },
  [IssuesTypes.issues]: { ...useCloneDeep(initialList), pagination: { page: 1, pageSize: SHOW_ISSUES_PER_PAGE }, initialCount: SHOW_ISSUES_PER_PAGE },
  [IssuesTypes.archivedIssues]: { ...useCloneDeep(initialList), pagination: { page: 1, pageSize: ARCHIVED_ISSUES }, initialCount: ARCHIVED_ISSUES },
} satisfies IssuesListsState

function getInitState() {
  return {
    // TODO: Make filters readonly and add mutation methods;
    search: '',
    sort: useCloneDeep(initialSort),
    ...useCloneDeep(defaultIssues),

    banner: null,

    maxAnnualInterestRate: 0,
    mobileFiltersOpened: false,

    totalCount: undefined,
    prefetchedIssuesCount: 0,
  } satisfies IssuesStoreState
}

export type IssuesFiltersState = DeepReadonly<Partial<IssuesListFilters>>

export const useIssuesStore = defineStore('issues', {
  state: (): IssuesStoreState => {
    return getInitState()
  },

  getters: {
    filters({ _filters }): IssuesFiltersState {
      return readonly(_filters)
    },

    activeSort(state: IssuesStoreState): () => ActiveSort | undefined {
      return () => {
        const activeSort = Object.entries(state.sort).find(([_, { active }]) => Boolean(active))
        return activeSort as ActiveSort | undefined
      }
    },

    baseRequestConfig(): (activeSort: [IssueSortKeys, IssueSort] | undefined) => Omit<getIssuesListServiceInput, 'pagination'> {
      return (activeSort: [IssueSortKeys, IssueSort] | undefined) => ({
        search: String(this.search),
        filters: this.filters,
        ...(activeSort
          ? { sort: { key: activeSort[0], direction: activeSort[1].direction } }
          : {}),
      })
    },

    ourIssuesIds(state): number[] {
      return state[IssuesTypes.onlyOurIssues].issues.map(({ id }) => Number(id))
    },

    /**
     * Get current count of shown issues
     * @param state
     * @returns {number} - count of shown issues
     */
    currentTotalCount(state): number {
      // Current total doesn't contain our issues
      return useSumBy(Object.values(IssuesTypes), issueType => state?.[issueType]?.totalCount?.current || 0)
    },

    loading(state: IssuesStoreState): number {
      return useSumBy(Object.values(IssuesTypes), issueType => state[issueType].loading)
    },

    appliedFilters(): { [key in keyof IssuesFiltersState]?: IssuesFiltersState[key] } {
      return removeEqualValues(useCloneDeep(this.filters), filtersInitialState)
    },

    issuesState({ allIssues, onlyOurIssues, issues: list, archivedIssues, banner }): IssuesListsState {
      const defaultIssues = useCloneDeep(list.issues)

      if (banner) {
        defaultIssues.splice(BANNER_POSITION, 0, {
          id: 'banner',
          banner: banner || undefined,
          loading: false,
        })
      }

      return {
        allIssues,
        onlyOurIssues,
        issues: {
          ...list,
          issues: defaultIssues, // with banner
        },
        archivedIssues,
      }
    },

    isDefaultSorting({ sort }) {
      return isEqual(
        sort,
        initialSort,
      )
    },
  },

  actions: {
    setPending(status: boolean) {
      useEach(IssuesTypes, issueType => status ? this[issueType].loading++ : this[issueType].loading--)
    },

    setFilters(filters: Partial<IssuesListFilters | IssuesFiltersState>): void {
      // Unknow because typescript doesn't know that we have all keys in filtersInitialState.
      Object.entries(filters).forEach(<Key extends IssuesFiltersKeys>(item: unknown) => {
        const [key, value] = item as [Key, IssuesListFilters[Key]]

        // If we have range filter and value is equal to default range, we don't need to set it.
        if (Object.keys(issuesFiltersRanges).includes(key)) {
          const rangedKey = key as keyof typeof issuesFiltersRanges
          const actualFilter = this.filters[key]

          if (
            actualFilter === filtersInitialState[key]
            && isEqual(value, [issuesFiltersRanges[rangedKey].min, issuesFiltersRanges[rangedKey].max])
          ) {
            return
          }
        }
        this._filters[key] = value
      })
    },

    async fetchBanner() {
      try {
        const banner = await getBanner()

        if (banner) {
          this.banner = banner
        }
      }
      catch (_) {
        // Nothing to do
      }
    },

    resetFilters() {
      this.search = ''
      this.setFilters(filtersInitialState)
    },

    async prefetchIssuesFilter() {
      // TODO: Resolve prefetching issues count type
      this.prefetchedIssuesCount = await api.issuesModule.getIssuesTotalCount({
        filters: this.filters,
        search: String(this.search),
        areArchivedIncluded: this.areArchivedIssuesShown(),
      })
    },

    /**
     * get issue from server
     * @param issuesType
     * @param params
     */
    getIssues(issuesType: IssuesTypes, params?: Partial<ExtraIssuesListFilters | undefined>): Promise<ApiResponseData<ShortIssueWithId[]>> {
      try {
        this.setPending(true)

        const activeSort = this.activeSort()
        const baseRequestConfig = this.baseRequestConfig(activeSort)

        return api.issuesModule.getIssuesList({
          ...baseRequestConfig,
          filters: {
            ...(baseRequestConfig.filters || {}),
            ...(params || {}),
          },
          pagination: this[issuesType].pagination,
        })
      }
      finally {
        this.setPending(false)
      }
    },

    saveIssuesToStore(
      issuesType: IssuesTypes,
      { data: issues, meta }: ApiResponseData<ShortIssueWithId[]>,
    ) {
      this[issuesType].issues = mapIssues(issues)

      if (meta) {
        this[issuesType].totalCount!.max = meta.pagination?.total || 0
        this[issuesType].totalCount!.current = issues?.length || 0
      }
    },

    fetchAllIssues() {
      return this.getIssues(IssuesTypes.allIssues, { isNotArchived: true })
    },

    fetchDefaultIssues() {
      return this.getIssues(IssuesTypes.issues, {
        excludedId: this.ourIssuesIds,
        isNotArchived: true,
      })
    },

    fetchOnlyOurIssues() {
      return this.getIssues(IssuesTypes.onlyOurIssues, {
        onlyOur: true,
        isNotArchived: true,
      })
    },

    fetchArchivedIssues() {
      return this.getIssues(IssuesTypes.archivedIssues, {
        excludedId: this.ourIssuesIds,
        isArchived: true,
      })
    },

    getLoaders() {
      return {
        [IssuesTypes.allIssues]: this.fetchAllIssues,
        [IssuesTypes.issues]: this.fetchDefaultIssues,
        [IssuesTypes.onlyOurIssues]: this.fetchOnlyOurIssues,
        [IssuesTypes.archivedIssues]: this.fetchArchivedIssues,
      }
    },

    async fetchInitialIssueList(): Promise<void> {
      // Load only our issues and default issues if we have default sort
      if (this.isDefaultSorting) {
        await this.fetchOnlyOurIssues().then(our => this.saveIssuesToStore(IssuesTypes.onlyOurIssues, our))
        await this.fetchDefaultIssues().then(defaults => this.saveIssuesToStore(IssuesTypes.issues, defaults))
      }
      else {
        // Load all issues if we have custom sort
        await this.fetchAllIssues().then(all => this.saveIssuesToStore(IssuesTypes.allIssues, all))
      }

      // Load archived issues if we have search
      if (this.areArchivedIssuesShown()) {
        this.fetchArchivedIssues().then(archived => this.saveIssuesToStore(IssuesTypes.archivedIssues, archived))
      }
    },

    // Load by scroll pagination
    async loadMoreIssues(issuesType: IssuesTypes) {
      /**
       * @description Current issues list
       */
      const self = this[issuesType]

      const lastIndex = self.issues.length

      const leftIssuesToLoad = self.totalCount!.max - self.totalCount!.current
      const count = leftIssuesToLoad > self.pagination.pageSize ? self.pagination.pageSize : leftIssuesToLoad
      // Make the placeholder array of issue with loading state
      for (let i = 0; i < count; i++) {
        self.issues.push({ loading: true, id: uuidv4() })
      }

      const loaders = this.getLoaders()

      const response = (await loaders[issuesType]?.())

      const issues: ShortIssueWithId[] | undefined = response?.data
      const meta = response?.meta

      if (meta) {
        self.totalCount!.max = meta.pagination?.total || 0
        self.totalCount!.current = this[issuesType].issues.length
      }

      issues?.forEach?.((issue, index) => {
        const currentIssue = self.issues[lastIndex + index]

        if (currentIssue) {
          currentIssue.loading = false
          currentIssue.issue = issue
        }
      })
    },

    setIssueTypePagination(type: IssuesTypes, pagination: Partial<PaginationByPage>) {
      this[type].pagination = {
        ...this[type].pagination,
        ...pagination,
      }
    },

    setSorting(key?: IssueSortKeys, direction?: SortDirection): void {
      if (!isUndefined(key)) {
        if (direction) {
          this.sort[key!].direction = direction
        }
        else {
          this.sort[key!].direction = this.sort[key!].direction === SortDirection.ASC
            ? SortDirection.DESC
            : SortDirection.ASC
        }

        Object.entries(this.sort).forEach(([itemKey, value]) => {
          value.active = itemKey === key
        })

        return
      }

      // set default sort when key is undefined
      this.sort = useCloneDeep(initialSort)
    },

    async fetchMaxTotalCount() {
      if (!this.totalCount) {
        this.totalCount = await getIssuesTotalCount()
      }

      return this.totalCount
    },

    setInitialFilters(requestURL: string) {
      const filtersFromPath = parseUrlToFilters(
        decodeURI(requestURL).split('?')[0].split('#')[0],
      )

      const parsedFiltersQuery = transformFullPathToQueryObject(requestURL, omit({
        ...useCloneDeep(filtersInitialState),
        search: String(this.search),
        sort: this.prepareSortQuery(),
      }, FILTERS_TO_BE_INCLUDED_IN_PATH))

      this.setFilters(usePick({
        ...useCloneDeep(filtersInitialState),
        ...parsedFiltersQuery,
        ...filtersFromPath,
      }, Object.keys(filtersInitialState)))

      if (parsedFiltersQuery.search) {
        // In any case company name has comma. We default parse it to Object.
        this.search = typeof parsedFiltersQuery.search === 'object'
          ? String(Object.values(parsedFiltersQuery.search)[0]) || ''
          : parsedFiltersQuery.search
      }
      else {
        this.search = ''
      }

      if (parsedFiltersQuery.sort) {
        const [sortKey, sortDirection] = parsedFiltersQuery.sort
        this.setSorting(sortKey as IssueSortKeys, sortDirection as SortDirection)
      }
    },

    getCurrentQuery() {
      type Filters = Partial<IssuesListFilters> & { sort: [IssueSortKeys, SortDirection] | null } & {
        search?: string
      }

      return transformFullPathToQueryObject(
        useRoute().fullPath,
      ) as Record<string, unknown> & Filters
    },

    async transformFiltersToUrl() {
      const parsedQuery = this.getCurrentQuery()

      const preparedSort = this.prepareSortQuery()
      const filterKeys = [...Object.keys(filtersInitialState), 'sort']
      const otherQuery = useOmit<Record<string, unknown>>(parsedQuery, filterKeys)

      if (!this.search?.trim?.()) {
        delete otherQuery.search
      }

      const queryObject = omit({
        ...otherQuery,
        ...{
          ...(String(this.search)?.trim() && { search: String(this.search) }),
          ...this.appliedFilters,
          ...(preparedSort && { sort: preparedSort }),
        },
      }, FILTERS_TO_BE_INCLUDED_IN_PATH)

      const path = buildURLFilterPath(this._filters)

      await useRouter().replace(
        `${path}${stringifyQuery(queryObject)}`,
      )
    },

    /**
     * Redirects initial filters to values in redirectsToFilters if doesn't find in current query
     * For example, if url doesn't contain hasEarlyRepayment filter, we will redirect to url with hasEarlyRepayment=true
     */
    async redirectsToChangedInitialFilters() {
      type redirectToFilter = {
        [key in keyof Partial<IssuesListFilters>]: IssuesListFilters[key]
      }

      const redirectsToFilters = {
        // here you can add default filters
        // example: [FiltersKeys.hasEarlyRepayment]: true,
      } satisfies redirectToFilter

      const currentQuery = this.getCurrentQuery()

      const resultQueryObject = omit({ ...redirectsToFilters, ...currentQuery }, FILTERS_TO_BE_INCLUDED_IN_PATH)

      const isQueryValid = filterQueryValidator(resultQueryObject)

      if (!isQueryValid) {
        throw new Error('Invalid query')
      }

      if (!isEqual(currentQuery, resultQueryObject)) {
        return await useRouter().replace(stringifyQuery(resultQueryObject))
      }

      return null
    },

    prepareSortQuery() {
      const [activeSort, activeSortValue] = Object.entries(this.sort).find(([_, { active }]) => active) || []

      if (activeSort && activeSortValue) {
        return [activeSort, activeSortValue.direction]
      }

      return null
    },

    areArchivedIssuesShown() {
      return String(this.search).trim().length > 0
    },

    resetListsPagination() {
      Object.keys(IssuesTypes).forEach((key) => {
        this.setIssueTypePagination(key as IssuesTypes, useCloneDeep(defaultIssues[key as IssuesTypes].pagination))
      })
    },

  },
})
