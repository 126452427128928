<script setup lang="ts">
import type { IssueDetails } from '~/api/modules/issues/services/getIssueDetails/getIssueDetails.types'

const props = defineProps<{
  issue: IssueDetails
}>()

const issue = toRef(props, 'issue')

const scoreLevelColor = computed(() => (!isNil(issue.value?.scoreData?.score)
  ? useScoreLevel({ score: issue.value.scoreData?.score })
  : null))
</script>

<template>
  <div v-if="!isNil(issue.scoreData?.score) && !isNil(scoreLevelColor)" class=" size-20 shrink-0">
    <ClientOnly>
      <LazyBaseProgresbarCircle
        :color="scoreLevelColor?.text"
        :total="19"
        :value="issue.scoreData?.score"
      />
    </ClientOnly>
  </div>
</template>
