import type { CfgTableDataSource } from '@cfgtech/ui'

export type TableRow = {
  name: string
  year?: number
  url: string
}

export enum DocumentAlertState {
  None = 'none',
  Warning = 'warning',
  Error = 'error',

}

export type State = {
  state: DocumentAlertState
  text: string
}

export type DocumentsTable = CfgTableDataSource<TableRow>

export type DocumentsTableItem = {
  title: string
  table: DocumentsTable
  alert: State | null
}
