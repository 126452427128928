<script setup lang="ts">
import { CfgButton, CfgDialog, CfgModalSizes, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { pick } from 'lodash-es'
import type { IssueCompletenessSections } from '~/composables/issues/useIssueCompleteness'

const props = defineProps<{
  summary: Record<IssueCompletenessSections, boolean>
  percentage: number
}>()

const recommendedSectionOrder = [
  'moneyUsagePlan',
  'collaterals',
  'aboutCompany',
  'returnCalendar',
  'financialResults',
  'score',
  'photoOrVideo',
  'files',
  'news',
  'motivation',
  'jamesOrderUrl',
  'tagline',
]

const notCompletedSections = computed(() => Object.fromEntries(Object.entries(pick(props.summary, recommendedSectionOrder)).filter(([, value]) => !value)))

const isOpened = defineModel('isOpened', {
  type: Boolean,
  default: false,
})

const { app: { ISSUER_APP_URL } } = useRuntimeConfig()
</script>

<template>
  <CfgDialog v-model:opened="isOpened" :size="CfgModalSizes.Medium">
    <template #title>
      <CfgTypography :size="CfgTypographySizes.h2">
        {{ $t('issue.completeness.modal.title') }}
      </CfgTypography>
    </template>

    <template #description>
      <div class="mt-6 flex flex-col gap-y-3">
        <CfgTypography :size="CfgTypographySizes.subtitle">
          {{ $t('issue.completeness.modal.description', { percentage: 100 - percentage }) }}
        </CfgTypography>

        <IssueDetailsV2CompletenessChip
          :completeness-percentage="percentage"
          :summary="summary"
        />
      </div>
    </template>

    <div class="space-y-6">
      <CfgTypography class="!font-highlighted" :size="CfgTypographySizes.md">
        {{ $t('issue.completeness.modal.recommendToFill') }}

        <ol class="flex flex-col">
          <template
            v-for="([key], index) in Object.entries(notCompletedSections)"
            :key="key"
          >
            <li
              v-if="notCompletedSections[key] === false"
              class="ml-2"
            >
              <div class="flex-none">
                <CfgTypography :size="CfgTypographySizes.md">
                  {{ index + 1 }}.
                  {{ $t(`issue.completeness.${key}`) }}
                </CfgTypography>
              </div>
            </li>
          </template>
        </ol>
      </CfgTypography>

      <CfgTypography class="block" :size="CfgTypographySizes.md">
        {{ $t('issue.completeness.modal.explanation') }}
      </CfgTypography>

      <GTMProvider v-slot="{ trackEvent, eventsList }">
        <NuxtLink
          class="block"
          target="_blank"
          :to="`${ISSUER_APP_URL}/auth/register`"
        >
          <CfgButton
            as="span"
            class="w-full rounded-xl"
            variant="primary"
            @click="trackEvent(eventsList.SrovSignUpStart, { location: DataLayerLocationsEnum.IssueDetails })"
          >
            {{ $t('issue.completeness.modal.button.label') }}
          </CfgButton>
        </NuxtLink>
      </GTMProvider>
    </div>
  </CfgDialog>
</template>
