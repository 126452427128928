import { useMfcrScoreFromIssueDetail } from './issues/useIssueMfcrScore'
import type { IssueDetails } from '~/api/modules/issues/services/getIssueDetails/getIssueDetails.types'
import { DetailsPageSections } from '~/stores/issues/types'

export function useSectionVisibility(issue: Ref<IssueDetails | null>) {
  const { t } = useI18n()

  const { parameters } = useIssuesDetails(issue, t)

  const { risk } = useMfcrScoreFromIssueDetail(issue)

  const userState = useUserState()

  function isIssueCompletedByData() {
    if (!issue.value) {
      return false
    }

    const { interestRate } = useIssueVariableYield({
      variableYieldInterestRateType: issue.value?.interestRate?.variableYieldInterestRateType,
      annualInterestRate: !issue.value.interestRate?.annualInterestRate
        ? ['-']
        : issue.value?.interestRate?.annualInterestRate?.toFixed(2)?.split?.('.') || ['0', '0'],
      variableYieldMargin: issue.value?.interestRate?.variableYieldMargin,
      yieldType: issue.value?.interestRate?.yieldType,
      annualInterestRateNote: issue.value?.interestRate?.annualInterestRateNote,
    })

    return Boolean(issue.value?.maturityDate
      && issue.value?.nominalValue
      && issue.value?.returnsPeriodicity
      // interest rate
      && (interestRate.value !== '-')
      && issue.value.collaterals.length
      && issue.value.company?.data.attributes.name
      && issue.value.company?.data.attributes.identification?.startDate
      && issue.value.company?.data.attributes.companyId
      && issue.value.fileCategories?.issueTermSheet?.files.length)
  }

  return (sectionId: DetailsPageSections): boolean => {
    if (!issue.value) {
      return false
    }

    switch (sectionId) {
      // done
      case DetailsPageSections.Video:
        return Boolean(issue.value.heroSection?.image?.data && issue.value.heroSection?.youtubeUrl)
      case DetailsPageSections.Collaterals:
        return true // Always visible

      case DetailsPageSections.ShowMoreIssues:
        return Boolean(issue.value.showMoreIssues?.length)
      case DetailsPageSections.NotCompletedIssue:
        return !isIssueCompletedByData()
      case DetailsPageSections.ReasonsToInvest:
        return Boolean(issue.value.motivation)
      case DetailsPageSections.MoneyUsagePlan:
        return Boolean(issue.value.moneyUsagePlan)
      case DetailsPageSections.CompanyEconomicResults:
        return !isEmpty(issue.value.company?.data?.attributes?.financialResults?.statements)
      case DetailsPageSections.ShowMoreIssuerIssues:
        return Boolean((issue.value.company?.data?.attributes?.issues?.data || [])?.length)
      case DetailsPageSections.Parameters:
        return parameters.some(parameter => parameter.value.trim() !== t('common.unavailable') && parameter.value.trim() !== t('common.undefined'))
      case DetailsPageSections.Documents:
        return Object.values(issue.value.fileCategories || {}).some(category => category?.files?.length > 0)
      case DetailsPageSections.InvestmentExample:
        return !isNil(issue.value.maturityDate) && !isNil(issue.value.nominalValue)
      case DetailsPageSections.InvestorPromo:
        return Boolean(!userState.value)
      case DetailsPageSections.CompanyDetails:
        return Object.values(useOmit(issue.value.company?.data?.attributes || {}, 'issues')).some(value => value)
      case DetailsPageSections.RiskRating:
        return !isNil(risk.value)
      case DetailsPageSections.MainParams:
        return !isNil(issue.value.maturityDate) || !isNil(issue.value.annualInterestRate) || !isNil(issue.value.nominalValue)
      case DetailsPageSections.InvestedChart:
        return (!isNil(issue.value.availableCount) && !isNil(issue.value.totalAmount))
      case DetailsPageSections.BlogPosts:
        return Boolean(issue.value.blogs?.data.length)
      case DetailsPageSections.AdvertisingMessage:
        return Boolean(issue)
      default:
        return true
    }
  }
}
