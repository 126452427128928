<script setup lang="ts">
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import type { IssueDetails } from '~/api/modules/issues/services/getIssueDetails/getIssueDetails.types'
import type { IssueBuyLocations } from '~/components/Issue/types'
import { getIssuePayoutProbability, useMfcrScoreFromIssueDetail } from '~/composables/issues/useIssueMfcrScore'

const props = defineProps<{
  issue: IssueDetails
  id: string
}>()

defineEmits<{
  'buy:issue': [location: IssueBuyLocations]
  'open:sidesheet': []
  'close:sidesheet': []
}>()

const issue = toRef(props, 'issue')

const payoutProbability = computed(() => (!isNil(issue.value?.scoreData?.score) ? getIssuePayoutProbability(issue.value.scoreData.score) : null))

const { risk } = useMfcrScoreFromIssueDetail(issue)
</script>

<template>
  <BaseSectionBox
    v-if="issue"
    :id="id"
    :button="{ label: $t('issue.issueRiskDetail.buttonDescription.label') }"
    :caption="$t('issue.issueName.title', { issueName: issue.name })"
    :title="$t('issue.mfcrScore.title')"
    @buy:issue="$emit('buy:issue', $event)"
    @close:sidesheet="$emit('close:sidesheet')"
    @open:sidesheet="$emit('open:sidesheet')"
  >
    <div class="flex items-center gap-x-6">
      <IssueDetailsV2RiskGraph :issue="issue" />

      <div>
        <CfgTypography :size="CfgTypographySizes.md" tag-name="p">
          <span v-if="risk" class="font-highlighted">
            {{ $t(`issuesListFilters.risks.${risk}`) }}
          </span>

          <span class="block">
            {{ $t(`issuesListFilters.payoutProbabilities.${payoutProbability}`) }}
          </span>
        </CfgTypography>
      </div>
    </div>

    <CfgTypography
      class="mt-3 flex flex-col gap-y-3 text-grey-400"
      :size="CfgTypographySizes.sm"
      tag-name="div"
    >
      <span class="block" v-html="$t('issue.mfcrScoreDescription')" />

      <IssueDetailsScoreCardChangeDate
        :mfcr-score-change-date="issue?.scoreData?.changeDate"
        :mfcr-score-year="issue?.company?.data?.attributes?.mfcrScoreYear"
      />
    </CfgTypography>

    <template #sidesheet:content>
      <LazyIssueDetailsV2RiskSideSheetContent :issue="issue" />
    </template>
  </BaseSectionBox>
</template>
