<script lang="ts" setup>
import { useDateFormat } from '@vueuse/core/index'
import { CfgHint, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { QuestionCircleIcon } from '@cfgtech/icons'
import type { Issue } from '~/api/types'
import { APP_DATE_FORMAT } from '~/constants/common'

const props = defineProps<{
  issue: Issue
}>()

const { t } = useI18n()

const paramsRootRef = ref<HTMLElement | null>(null)

const isMobileVersionShown = useContainerMediaQuery(paramsRootRef, {
  lessThen: 550,
})

const formattedMaturityDate = computed(() => !props.issue.maturityDate ? '-' : useDateFormat(new Date(props.issue.maturityDate), APP_DATE_FORMAT).value)

const { interestRate, interestRateDescription, interestRateTooltip } = useIssueVariableYield({
  variableYieldInterestRateType: props.issue.interestRate?.variableYieldInterestRateType,
  annualInterestRate: !props.issue.interestRate?.annualInterestRate
    ? ['-']
    : props.issue.interestRate.annualInterestRate.toFixed(2)?.split?.('.') || ['0', '0'],
  variableYieldMargin: props.issue.interestRate?.variableYieldMargin,
  yieldType: props.issue.interestRate?.yieldType,
  annualInterestRateNote: props.issue.interestRate?.annualInterestRateNote,
})

// Main parameters of issue
const mainParams = computed(() => ({
  maturityDate: {
    label: t('issue.maturityDate.title'),
    value: formattedMaturityDate.value?.replace(/\s/g, '&nbsp;'),
    tooltip: t('issue.maturityDate.hint'),
    description: props.issue.hasEarlyRepayment ? t('bondList.hasEarlyRepayment.label') : undefined,
  },
  annualInterestRate: {
    label: t('issue.annualInterestRate.title'),
    value: interestRate.value,
    ...(interestRateDescription.value && { description: interestRateDescription.value }),
    tooltip: interestRateTooltip.value
    || (t('issue.annualYield.hint') + (props.issue.interestRate?.annualInterestRateNote ? `<p class=mt-4>${props.issue.interestRate?.annualInterestRateNote}</p>` : '')),
  },
  returnsPeriodicity: {
    label: t('issue.returnsPeriodicity.title'),
    value: props.issue.returnsPeriodicity ?? '-',
    tooltip: t('issue.returnsPeriodicity.tooltip'),
  },
  nominalValue: {
    label: t('issue.nominalValueItems.title', { count: 1 }),
    value: props.issue.nominalValue ? `${useMoneyFormat(props.issue.nominalValue, true)} ${t(`common.currencies.${props.issue.currency}`)}`.replace(/\s/g, '&nbsp;') : '-',
    tooltip: t('issue.nominalValue.hint'),
  },
}))
</script>

<template>
  <ul
    ref="paramsRootRef"
    :class="{
      'space-y-4': isMobileVersionShown,
      'flex justify-between': !isMobileVersionShown,
    }"
  >
    <li
      v-for="{ label, value, tooltip, description } in mainParams"
      :key="label"
    >
      <CfgHint
        class="w-full"
        :disabled="!tooltip"
        hide-icon
        interactive
      >
        <template #trigger>
          <div
            class="flex flex-1 gap-x-5"
            :class="{
              'items-center': isMobileVersionShown,
              '!flex-col gap-y-2': !isMobileVersionShown,
            }"
          >
            <CfgTypography
              class="flex grow items-center gap-x-2 !font-highlighted text-grey-400"
              :size="CfgTypographySizes.md"
              tag-name="p"
            >
              <span class="text-sm md:text-[inherit]">{{ label }}</span>

              <QuestionCircleIcon v-if="tooltip" class="mt-0 text-[1.4rem] text-brand" />
            </CfgTypography>

            <CfgTypography
              :class="{
                'text-right': isMobileVersionShown,
              }"
              :size="CfgTypographySizes.h3"
              tag-name="p"
            >
              <span v-html="value" />

              <small
                v-if="description"
                class="mt-1 block text-[0.78em] font-normal lowercase md:mt-1.5 lg:text-[0.67em]"
                v-html="description"
              />
            </CfgTypography>
          </div>
        </template>

        <template #content="{ initialized }">
          <span v-if="initialized" v-html="tooltip" />
        </template>
      </CfgHint>
    </li>
  </ul>
</template>
