<script lang="ts" setup>
import { CfgDialog, CfgModalSizes, CfgPreloader } from '@cfgtech/ui'
import type { Media } from '@api-types/Media'

type Props = {
  src?: string
  supportUrl: string
  image?: Media
  video?: Media
}

const props = defineProps<Props>()

const videoModalOpened = ref(false)
const videoLoaded = ref(false)

const imageSrc = computed(() => props.image?.attributes?.url)
const videoSrc = computed(() => props.video?.attributes?.url)

const isMediaVideo = computed(() => Boolean(props.video))

const ytVideoSrc = computed(() => {
  if (!props.src) {
    return undefined
  }

  const url = new URL(props.src)
  url.searchParams.set('autoplay', '1')
  url.searchParams.set('modestbranding', '1')
  return url.href
})

watch([() => props.src, videoModalOpened], () => {
  videoLoaded.value = false
}, { deep: true })

function onPlayClick(): void {
  videoModalOpened.value = true
}
</script>

<template>
  <div
    class="relative w-full"
    :class="{
      'min-h-[240px]': src || imageSrc,
    }"
  >
    <div
      v-if="src || isMediaVideo"
      class="group absolute inset-0 flex size-full items-center justify-center rounded-xl bg-grey-100 text-brand"
      @click="onPlayClick"
    >
      <img
        v-if="imageSrc"
        :alt="image?.attributes?.alternativeText || ''"
        class="absolute inset-0 size-full rounded-xl object-cover object-center"
        loading="lazy"
        :src="imageSrc"
      >

      <button class="z-[1] size-14 border-none transition-transform duration-200 focus-visible:outline-none group-hover:-translate-y-1" type="button">
        <img
          alt="Play"
          class="block size-full object-contain object-center"
          loading="lazy"
          src="/img/play.svg"
        >
      </button>
    </div>

    <img
      v-else-if="imageSrc && !isMediaVideo"
      :alt="image?.attributes?.alternativeText || ''"
      class="absolute inset-0 size-full rounded-xl object-cover object-center"
      loading="lazy"
      :src="imageSrc"
    >
  </div>

  <ClientOnly>
    <CfgDialog
      v-model:opened="videoModalOpened"
      flat
      :size="CfgModalSizes.XLarge"
    >
      <!-- VIDEO FROM MEDIA LIBRARY -->
      <div v-if="isMediaVideo" class="relative pt-[50%]">
        <video
          :alt="video?.attributes?.alternativeText || ''"
          autoplay
          class="absolute inset-0 size-full rounded-xl object-cover object-center"
          controls
          loading="lazy"
        >
          <source :src="videoSrc" :type="video?.attributes?.mime">
        </video>
      </div>

      <!-- YOUTUBE VIDEO -->
      <div v-else class="relative pt-[50%]">
        <div v-if="!videoLoaded" class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <CfgPreloader class=" text-brand" />
        </div>

        <iframe
          v-if="src"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="absolute inset-0 size-full overflow-hidden rounded-xl object-cover object-center"
          frameborder="0"
          loading="lazy"
          :src="ytVideoSrc"
          @load="videoLoaded = true"
        />
      </div>
    </CfgDialog>
  </ClientOnly>
</template>
