import { IssuesInvestorType, IssuesRisks } from './../api/modules/issues/services/getIssuesList/getIssuesList.types'
import { getScoreByMfCrScoreRangeValue } from '~/api/modules/issues/services/getIssuesList/getIssuesList.service'
import { ChipVariants } from '~/components/base/Chip/types'
import { theme } from '~/theme'

export function useScoreLevel(value: { risk?: IssuesRisks, investorType?: IssuesInvestorType, score?: number }) {
  let level: IssuesRisks | IssuesInvestorType | number = 0

  if (value.risk) {
    level = value.risk
  }

  if (value.investorType) {
    level = value.investorType
  }

  if (value.score) {
    level = getScoreByMfCrScoreRangeValue(value.score)
  }

  const output: { text: string, background: string, backgroundText: string, chip: ChipVariants } = {
    text: theme.colors.success.default,
    background: theme.colors.success.default,
    backgroundText: '#fff',
    chip: ChipVariants.lowest,
  }

  switch (level) {
    case IssuesRisks.Low:
    case IssuesInvestorType.Conservative:
    case 4:
      output.background = theme.colors.success.opacity
      output.backgroundText = theme.colors.success.default
      output.text = theme.colors.success.default
      output.chip = ChipVariants.low

      return output

    case IssuesRisks.Medium:
    case IssuesInvestorType.Defensive:
    case IssuesInvestorType.Balanced:
    case 3:
      output.background = theme.colors.yellow.default
      output.text = theme.colors.yellow.default
      output.backgroundText = '#fff'
      output.chip = ChipVariants.medium

      return output

    case IssuesRisks.High:
    case IssuesInvestorType.Dynamic:
    case 2:
      output.background = theme.colors.warning.light
      output.text = theme.colors.warning.default
      output.backgroundText = theme.colors.warning.default
      output.chip = ChipVariants.high

      return output

    case IssuesRisks.Highest:
    case IssuesInvestorType.Aggresive:
    case 1:
    case 0:
      output.background = theme.colors.warning.default
      output.text = theme.colors.warning.default
      output.backgroundText = '#fff'
      output.chip = ChipVariants.highest

      return output

    default:
      return output
  }
}
