import { omit } from 'lodash-es'
import type { IssueDetails } from '~/api/modules/issues/services/getIssueDetails/getIssueDetails.types'
import type { Return } from '~/api/types'

export enum IssueCompletenessSections {
  moneyUsagePlan = 'moneyUsagePlan',
  collaterals = 'collaterals',
  aboutCompany = 'aboutCompany',
  returnCalendar = 'returnCalendar',
  financialResults = 'financialResults',
  score = 'score',
  photoOrVideo = 'photoOrVideo',
  files = 'files',
  news = 'news',
  motivation = 'motivation',
  jamesOrderUrl = 'jamesOrderUrl',
  tagline = 'tagline',
}

export function useIssueCompleteness(issue: Ref<IssueDetails | null>) {
  const isIssueCompletenessModalOpened = ref(false)

  const maxPoint = Object.keys(IssueCompletenessSections).length

  const summary = ref<Record<IssueCompletenessSections, boolean>>({
    tagline: false,
    photoOrVideo: false,
    jamesOrderUrl: false,
    motivation: false,
    moneyUsagePlan: false,
    collaterals: false,
    aboutCompany: false,
    returnCalendar: false,
    financialResults: false,
    score: false,
    files: false,
    news: false,
  })

  const isIssueCompleted = computed(() => Object.values(summary.value).every(value => value))

  function flagSectionAsCompleted(section: IssueCompletenessSections) {
    summary.value[section] = true
  }

  const completenessPercentage = computed(() => {
    // tagline
    if (issue.value?.tagline) {
      flagSectionAsCompleted(IssueCompletenessSections.tagline)
    }

    // video or photo
    if (issue.value?.heroSection?.image?.data?.attributes.url || issue.value?.heroSection?.youtubeUrl) {
      flagSectionAsCompleted(IssueCompletenessSections.photoOrVideo)
    }

    // james order url
    if (issue.value?.jamesOrderFormURL) {
      flagSectionAsCompleted(IssueCompletenessSections.jamesOrderUrl)
    }

    // motivation
    if (issue.value?.motivation) {
      flagSectionAsCompleted(IssueCompletenessSections.motivation)
    }

    // money usage plan
    if (issue.value?.moneyUsagePlan) {
      flagSectionAsCompleted(IssueCompletenessSections.moneyUsagePlan)
    }

    // collaterals
    if (issue.value?.collaterals.filter(collateral => collateral.type).length) {
      flagSectionAsCompleted(IssueCompletenessSections.collaterals)
    }

    // about company
    if (issue.value?.company?.data.attributes.extendedDetail?.shortDescription || issue.value?.company?.data.attributes.extendedDetail?.description) {
      flagSectionAsCompleted(IssueCompletenessSections.aboutCompany)
    }

    // return calendar
    if (issue.value?.returns.some((returnPeriod: Return) => returnPeriod.isPaid === false || returnPeriod.isPaid === true)) {
      flagSectionAsCompleted(IssueCompletenessSections.returnCalendar)
    }

    // financial results
    const year = new Date().getFullYear()
    if (issue.value?.company?.data.attributes.financialResults?.statements.some(statement => (statement.year || 0) >= year - 2)) {
      flagSectionAsCompleted(IssueCompletenessSections.financialResults)
    }

    // score
    if (issue.value?.scoreData?.score) {
      flagSectionAsCompleted(IssueCompletenessSections.score)
    }

    // news
    if (issue.value?.company?.data.attributes.news?.length) {
      flagSectionAsCompleted(IssueCompletenessSections.news)
    }

    // files
    const hasCollateralsFiles = issue.value?.collaterals.some(collateral => collateral.files.length)
    const hasIssueFiles = Object.values(omit(issue.value?.fileCategories, ['id', 'collateralInstruments'])).some(category => category.files.length)
    const hascompanyFiles = Object.values(omit(issue.value?.company?.data.attributes.fileCategories, ['id'])).some(category => category.files.length)

    if (hasCollateralsFiles || hasIssueFiles || hascompanyFiles) {
      flagSectionAsCompleted(IssueCompletenessSections.files)
    }

    const completedSections = Object.values(summary.value).filter(value => value).length
    return Math.round((completedSections / maxPoint) * 100)
  })

  return {
    isIssueCompletenessModalOpened,
    completenessPercentage,
    summary,
    isIssueCompleted,
  }
}
