export default function useBooleanTranslator(t: ReturnType<typeof useI18n>['t']) {
  const yesNo = (value: boolean): string => t(value ? 'common.yes' : 'common.no')

  const yesNoNull = (value: boolean | null | undefined): string => {
    if (typeof value === 'boolean') {
      return yesNo(value)
    }

    return t('common.undefined')
  }

  return {
    yesNo,
    yesNoNull,
  }
}
