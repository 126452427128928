<script lang="ts" setup>
import { QuestionCircleIcon } from '@cfgtech/icons'
import { CfgButton, CfgPreloader, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { objectEntries } from '@vueuse/core'
import { api } from '~/api/Api'
import type { MetaSocial } from '~/api/types/generated/components/MetaSocial'
import MoneyUsagePlan from '~/components/Issue/Details/v2/MoneyUsagePlan/MoneyUsagePlan.vue'
import type { anchorNavigation } from '~/components/Issue/Details/v2/Navigation/Navigation.types'
import type { IssueBuyEmit, IssueBuyLocations } from '~/components/Issue/types'
import { IssueBuyUrlTypeEnum } from '~/components/Issue/types'
import type { IssueWarning } from '~/composables/company/types/types'
import { useLiquidation } from '~/composables/company/useLiquidation'
import { useUnreliableVat } from '~/composables/company/useUnreliableVat'
import { useHandleBuyIssueModalCloseSendEvent } from '~/composables/issues/useHandleBuyIssueModalCloseSendEvent'
import { useIssueCompleteness } from '~/composables/issues/useIssueCompleteness'
import { DataLayerLocationsEnum } from '~/composables/useDataLayer'
import { HEADER_HEIGHT_DESKTOP_WITH_ISSUE_NAVIGATION, SUPPORT_URL } from '~/constants/common'
import { paths } from '~/constants/paths'
import { DetailsPageSections } from '~/stores/issues/types'

const route = useRoute()
const previousRoute = usePreviousRoute()

definePageMeta({
  path: paths.issueDetail,
})

const {
  onMoreInfoContactClickEvent,
  onPhoneClickEvent,
  onEmailClickEvent,
  pushIssueToDataLayer,
  onContactIssuerSubmitEvent,
  onContactIssuerClickEvent,
  onBuyNowClickEvent,
  onSrovOrderOnlineModalClick,
  onSrovSidesheetClick,
  onSrovSidesheetClose,
  onSrovAffiliateRedirectClick,
  onSrovAffiliateRedirectModalClick,
  onCallpageMainOpenEvent,
  onSrovComment,
} = useDataLayer()

const { $logTypeformPageVisit, $bonds } = useNuxtApp()
const { orderURL, issue: availabilityIssue } = useIssueOrderAvailability()
const responsive = useResponsive()
const { t } = useI18n()

const issueSlug = computed(() => route.params?.slug as string)

const { data: issue, pending } = await useAsyncData(
  `issue-${issueSlug.value}`,
  async () => {
    try {
      const companyId = new URLSearchParams(useRequestURL().search).get('companyId')
      return await api.issuesModule.getIssueDetails(issueSlug.value, companyId ? Number(companyId) : undefined)
    }
    catch (_) {
      showError({ statusCode: 404, message: 'Not found' })
      return null
    }
  },
  { watch: [issueSlug] },
)

if (issue.value) {
  if (issueSlug.value !== issue.value?.slug) {
    await navigateTo({
      ...route,
      params: { ...route.params, slug: issue.value?.slug },
    }, { redirectCode: 301 })
  }

  pushIssueToDataLayer(issue.value)
  useIssueSchema(issue.value)

  availabilityIssue.value = issue.value

  setSeo()
}

const orderUrlType = computed(() => {
  if (issue.value?.jamesOrderFormURL) {
    return IssueBuyUrlTypeEnum.JAMES
  }

  // if (issue.value?.affiliateURL) {
  //   return IssueBuyUrlTypeEnum.AFFILIATE;
  // }

  return null
})

provide('orderURL', orderURL)
provide('orderURLType', orderUrlType)

const isSectionVisible = useSectionVisibility(issue)

const showSection = computed(() => issue.value?.showBuyButton !== false)

const sections = computed(
  () => {
    const { parameters } = useIssuesDetails(issue, t)

    const insolvency = (issue.value?.company?.data?.attributes && useInsolvency(issue.value.company.data.attributes))
    const liquidation = (issue.value?.company?.data?.attributes && useLiquidation(issue.value.company.data.attributes))
    const unreliableVat = (issue.value?.company?.data?.attributes && useUnreliableVat(issue.value.company.data.attributes))

    const warnings = [
      Boolean(insolvency?.show) && insolvency?.lustration,
      Boolean(liquidation?.show) && liquidation?.lustration,
      Boolean(unreliableVat?.show) && unreliableVat?.lustration,
    ].filter(Boolean) as IssueWarning[]

    return {
      [DetailsPageSections.Video]: {
        order: 'order-1',
        id: DetailsPageSections.Video,
        isAvailable: isSectionVisible(DetailsPageSections.Video),
      },
      [DetailsPageSections.MainParams]: {
        order: 'order-4 lg:order-3',
        id: DetailsPageSections.MainParams,
        isAvailable: isSectionVisible(DetailsPageSections.MainParams),
      },
      [DetailsPageSections.InvestedChart]: {
        order: 'order-5 lg:order-4',
        id: DetailsPageSections.InvestedChart,
        isAvailable: isSectionVisible(DetailsPageSections.InvestedChart),
      },
      [DetailsPageSections.Warnings]: {
        order: 'order-3 lg:order-5',
        id: DetailsPageSections.Warnings,
        isAvailable: Boolean(warnings.length),
        data: warnings,
      },
      [DetailsPageSections.MobileCalculator]: {
        order: 'order-6',
        id: DetailsPageSections.MobileCalculator,
        isAvailable: issue.value && responsive.value.mobile === true,
      },
      [DetailsPageSections.ContactCard]: {
        order: 'order-7',
        id: DetailsPageSections.ContactCard,
        isAvailable: true,
      },
      [DetailsPageSections.ReasonsToInvest]: {
        order: 'order-8',
        id: DetailsPageSections.ReasonsToInvest,
        isAvailable: isSectionVisible(DetailsPageSections.ReasonsToInvest) && showSection,
      },
      [DetailsPageSections.MoneyUsagePlan]: {
        order: 'order-9 lg:order-9',
        id: DetailsPageSections.MoneyUsagePlan,
        isAvailable: isSectionVisible(DetailsPageSections.MoneyUsagePlan),
      },
      [DetailsPageSections.Collaterals]: {
        order: 'order-10',
        id: DetailsPageSections.Collaterals,
        isAvailable: isSectionVisible(DetailsPageSections.Collaterals),
      },
      [DetailsPageSections.InvestorPromo]: {
        order: 'order-11',
        id: DetailsPageSections.InvestorPromo,
        isAvailable: isSectionVisible(DetailsPageSections.InvestorPromo),
      },
      [DetailsPageSections.CompanyDetails]: {
        order: 'order-12',
        id: DetailsPageSections.CompanyDetails,
        isAvailable: isSectionVisible(DetailsPageSections.CompanyDetails),
      },
      [DetailsPageSections.ReturnCalendar]: {
        order: 'order-[13]',
        id: DetailsPageSections.ReturnCalendar,
        isAvailable: isSectionVisible(DetailsPageSections.ReturnCalendar),
      },
      [DetailsPageSections.BlogPosts]: {
        order: 'order-[14]',
        id: DetailsPageSections.BlogPosts,
        isAvailable: isSectionVisible(DetailsPageSections.BlogPosts),
      },
      [DetailsPageSections.CompanyEconomicResults]: {
        order: 'order-[15]',
        id: DetailsPageSections.CompanyEconomicResults,
        isAvailable: isSectionVisible(DetailsPageSections.CompanyEconomicResults),
      },
      [DetailsPageSections.RiskRating]: {
        order: 'order-[16]',
        id: DetailsPageSections.RiskRating,
        isAvailable: isSectionVisible(DetailsPageSections.RiskRating),
      },
      [DetailsPageSections.News]: {
        order: 'order-[17]',
        id: DetailsPageSections.News,
        isAvailable: true,
      },
      [DetailsPageSections.Documents]: {
        order: 'order-[18]',
        id: DetailsPageSections.Documents,
        isAvailable: isSectionVisible(DetailsPageSections.Documents),
      },

      [DetailsPageSections.Parameters]: {
        order: 'order-[19]',
        id: DetailsPageSections.Parameters,
        isAvailable: isSectionVisible(DetailsPageSections.Parameters),
        data: parameters,
      },
      [DetailsPageSections.InvestmentExample]: {
        order: 'order-[20]',
        id: DetailsPageSections.InvestmentExample,
        isAvailable: isSectionVisible(DetailsPageSections.InvestmentExample) && issue?.value?.showBuyButton,
      },
      [DetailsPageSections.ShowMoreIssuerIssues]: {
        order: 'order-[21]',
        id: DetailsPageSections.ShowMoreIssuerIssues,
        isAvailable: isSectionVisible(DetailsPageSections.ShowMoreIssuerIssues),
      },
      [DetailsPageSections.ShowMoreIssues]: {
        order: 'order-[22]',
        id: DetailsPageSections.ShowMoreIssues,
        isAvailable: isSectionVisible(DetailsPageSections.ShowMoreIssues),
      },
      [DetailsPageSections.AdvertisingMessage]: {
        order: 'order-2',
        id: DetailsPageSections.AdvertisingMessage,
        isAvailable: isSectionVisible(DetailsPageSections.AdvertisingMessage),
      },
      [DetailsPageSections.NotCompletedIssue]: {
        order: 'order-last',
        id: DetailsPageSections.NotCompletedIssue,
        isAvailable: isSectionVisible(DetailsPageSections.NotCompletedIssue),
      },
    }
  },
)

const sectionsToHandle = [
  DetailsPageSections.Warnings,
  DetailsPageSections.MoneyUsagePlan,
  DetailsPageSections.ReasonsToInvest,
  DetailsPageSections.Collaterals,
  DetailsPageSections.CompanyDetails,
  DetailsPageSections.ReturnCalendar,
  DetailsPageSections.RiskRating,
  DetailsPageSections.CompanyEconomicResults,
  DetailsPageSections.Documents,
]

const sectionsInViewport = ref([] as DetailsPageSections[])

function handleOnViewport(id: DetailsPageSections, isVisible: boolean) {
  if (isVisible) {
    sectionsInViewport.value.push(id)
    return
  }
  sectionsInViewport.value = sectionsInViewport.value.filter(section => section !== id)
}

provide('handleOnViewport', handleOnViewport)

const lastScrollDirection = ref('down')
let lastScrollPosition = 0

useEventListener('scroll', () => {
  const scrollDirection = lastScrollPosition < window.scrollY ? 'down' : 'up'

  if (scrollDirection !== lastScrollDirection.value) {
    lastScrollDirection.value = scrollDirection
  }

  handleTypeformPopup()
  lastScrollPosition = window.scrollY
})

const sectionsToTableOfContents = computed(() => {
  const firstSectinInViewport: DetailsPageSections | null = lastScrollDirection.value === 'down'
    ? sectionsInViewport.value[0]
    : sectionsInViewport.value[sectionsInViewport.value.length - 1]

  return objectEntries(sections.value)
    .filter(([_, { isAvailable, id }]) => sectionsToHandle.includes(id) && isAvailable)
    .map(([_, { id }]) => ({
      anchor: id,
      active: id === firstSectinInViewport,
      text: t(`issue.nav.${id}`),
    } satisfies anchorNavigation[0]))
})

const {
  summary,
  completenessPercentage,
  isIssueCompletenessModalOpened,
  isIssueCompleted,
} = useIssueCompleteness(issue)

const {
  issueToBuy,
  orderUrl,
  isIssueBuyModalOpen,
  issueBuyModalType,
  openIssueBuyModal,
  closeIssueBuyModal,
} = useIssueBuy()

const { handleBuyIssueModalCloseSendEvent } = useHandleBuyIssueModalCloseSendEvent()

const isContactIssuerDialogOpened = ref(false)
const isContactIssuerErrorDialogOpened = ref(false)
const isContactIssuerSuccessDialogOpened = ref(false)
const investmentCount = ref(0)
const issueBuyLocation = ref<IssueBuyLocations>(
  DataLayerLocationsEnum.IssueDetails,
)

const blogPosts = computed(() => issue.value?.blogs?.data || [])

function handleCommentsDataLayerUpdate() {
  onSrovComment(route.fullPath, issue.value)
}

function handleIssuerSuccessDialogOpen() {
  if (!issue.value) {
    return
  }

  onContactIssuerSubmitEvent(issue.value)

  setTimeout(() => {
    // TODO: `CfgDialog` have `@after-close-animation`, we can use it instead of this.
    isContactIssuerSuccessDialogOpened.value = true
  }, 250) // Wait for the dialog to close
}

function handleContactIssuerErrorDialogOpen() {
  if (!issue.value) {
    return
  }

  onContactIssuerSubmitEvent(issue.value)

  setTimeout(() => {
    isContactIssuerErrorDialogOpened.value = true
  }, 250)
}

function handleContactIssuerAboutDialogOpen(location: 'about' | 'banner') {
  if (!issue.value) {
    return
  }

  onContactIssuerClickEvent(issue.value, location)
  isContactIssuerDialogOpened.value = true
}

function handleIssueBuy(
  location: IssueBuyLocations = DataLayerLocationsEnum.IssueDetails,
) {
  if (!issue.value || !orderUrlType.value || !orderURL.value) {
    return
  }

  if (orderUrlType.value === IssueBuyUrlTypeEnum.JAMES) {
    const amount = issue.value?.nominalValue
      ? investmentCount.value / issue.value.nominalValue
      : 0

    const orderUrlWithAmount = `${orderURL.value}&amount=${amount}`

    openIssueBuyModal({
      issue: issue.value,
      url: { orderUrl: orderUrlWithAmount, urlType: orderUrlType.value },
    })

    onSrovOrderOnlineModalClick(issue.value, location)
  }
  else if (orderUrlType.value === IssueBuyUrlTypeEnum.AFFILIATE) {
    openIssueBuyModal({
      issue: issue.value,
      url: { orderUrl: orderURL.value, urlType: orderUrlType.value },
    })

    onSrovAffiliateRedirectModalClick(issue.value, location)
  }

  issueBuyLocation.value = location
}

function handleMoreIssueBuy(data: IssueBuyEmit) {
  if (!data.issue || !data.url.urlType) {
    return
  }

  openIssueBuyModal(data)

  if (issueBuyModalType.value === IssueBuyUrlTypeEnum.JAMES) {
    onSrovOrderOnlineModalClick(
      data.issue,
      DataLayerLocationsEnum.IssueDetails,
    )
  }
  else if (issueBuyModalType.value === IssueBuyUrlTypeEnum.AFFILIATE) {
    onSrovAffiliateRedirectModalClick(
      data.issue,
      DataLayerLocationsEnum.IssueDetails,
    )
  }
}

function handleIssueBuyModalClose(closeType: 'backdrop' | 'button' | 'custom') {
  if (!issue.value || !issueBuyModalType.value) {
    return
  }

  handleBuyIssueModalCloseSendEvent(
    closeType,
    issue.value,
    issueBuyModalType.value,
    issueBuyLocation.value,
  )

  closeIssueBuyModal()
}

function handleBuyNowEventClick() {
  if (!issueToBuy.value) {
    return
  }

  closeIssueBuyModal()

  switch (issueBuyModalType.value) {
    case IssueBuyUrlTypeEnum.AFFILIATE:
      onSrovAffiliateRedirectClick(issueToBuy.value, issueBuyLocation.value)
      break

    case IssueBuyUrlTypeEnum.JAMES:
      onBuyNowClickEvent(issueToBuy.value, issueBuyLocation.value)
      break

    default:
      break
  }
}

function setSeo() {
  if (!issue.value) {
    return
  }

  const seo = issue.value.seo

  useSeo({
    id: 0,
    metaTitle: `${t('issue.issue')} ${seo?.metaTitle || issue.value?.name} | ${t('issue.issueSlugTitle')}`,
    metaDescription: seo?.metaDescription || t('common.seo.slugDescription', { issueName: issue.value?.name }),
    metaSocial: (seo?.metaSocial || []).map((social): MetaSocial => ({
      socialNetwork: social.socialNetwork,
      title: social.title || issue.value?.name || '',
      description: social.description || t('common.seo.slugDescription', { issueName: issue.value?.name }),
      image: { // todo change to Omit<MetaSocial, 'image'> & { image?: string }
        data: {
          attributes: {
            url: social.image?.data?.attributes?.url,
          },
        },
      },
      imageAlternativeText: social.imageAlternativeText || t('common.seo.imageAltSlug', { name: issue.value?.name }),
    } as MetaSocial)) || [],
  }, {
    url: issue.value?.heroSection?.image?.data?.attributes?.url,
    alternativeText: issue.value?.heroSection?.image?.data?.attributes?.alternativeText,
  })

  useHead({
    title: `${t('issue.issue')} ${seo?.metaTitle || issue.value.name} | ${t('issue.issueSlugTitle')}`,
  })
}

let isPageVisitLogged = false
function handleTypeformPopup() {
  if (isPageVisitLogged || window.scrollY < 150 || !issue.value?.isin) {
    return
  }

  $logTypeformPageVisit(issue.value.isin)

  isPageVisitLogged = true
}

// Set metadata on every load of page
// useAsyncData loads only when changing url, not on page reload for example
onMounted(setSeo)

watch(issue, setSeo)

// Investor section
const { public: { investorAppUrl } } = useRuntimeConfig()

const userState = useUserState()

const investorIssueInvestments = ref(0)

watch(userState, async (user) => {
  if (user) {
    const data = await $bonds('/api/investors/v1/orders/', {
      query: { ISSUE_ISIN: issue.value!.isin! },
      headers: { 'X-CFG-INVESTOR-USER-ID': user.sub },
      header: { 'X-CFG-INVESTOR-USER-ID': user.sub },
    })

    if (data) {
      investorIssueInvestments.value = data.reduce((sum, item) => sum + Number(item.value), 0)
    }
  }
}, { immediate: true, deep: true })
</script>

<template>
  <div class="flex flex-col">
    <div v-if="issue && !pending" class="container flex-1">
      <LazyIssueDetailsV2NavigationContainerWrapper
        :nav="sectionsToTableOfContents"
        :offset-top="500"
        :title="issue?.name || ''"
      />

      <!-- BREADCRUMB -->
      <LazyBaseBack :to="previousRoute">
        {{ $t("issue.backToCorporateIssues") }}
      </LazyBaseBack>

      <!--  ISSUES TITLE   -->
      <div v-if="issue" class="mt-6">
        <IssueDetailsV2Title
          :completeness-percentage="completenessPercentage"
          :completeness-summary="summary"
          :issue="issue"
        />
      </div>

      <div class="mt-6 grid grid-cols-1 gap-x-10 lg:auto-rows-fr lg:grid-cols-12">
        <!-- MAIN PAGE CONTENT -->
        <main class="lg:col-span-7">
          <div v-if="issue" class="flex flex-col [&>*]:w-full">
            <IssueDetailsV2CompletenessBanner
              v-if="!isIssueCompleted"
              class="mb-10 lg:mb-6"
              @open-issue-completeness-modal="isIssueCompletenessModalOpened = true"
            />

            <!-- TAGLINE -->
            <section v-if="issue.tagline" class="mb-10 lg:mb-6">
              <CfgTypography :size="CfgTypographySizes.md" tag-name="p">
                {{ issue.tagline }}
              </CfgTypography>
            </section>

            <!--  ISSUES VIDEO / POSTER   -->
            <div
              v-if="issue?.heroSection?.youtubeUrl || issue?.heroSection?.video?.data || issue?.heroSection?.image?.data"
              :id="sections[DetailsPageSections.Video].id"
              class="mt-5"
              :class="sections[DetailsPageSections.Video].order"
            >
              <IssueDetailsHeroSectionVideo
                :image="issue?.heroSection?.image?.data"
                :src="issue?.heroSection?.youtubeUrl"
                :support-url="SUPPORT_URL"
                :video="issue?.heroSection?.video?.data"
              />
            </div>

            <!--  MAIN PARAMS OF ISSUE, LIKE: MATURITY, NOMINAL VALUE  -->

            <div
              :id="sections[DetailsPageSections.MainParams].id"
              class="mt-10 lg:mt-6"
              :class="sections[DetailsPageSections.MainParams].order"
            >
              <IssueDetailsV2MainParams :issue="issue" />
            </div>

            <!--  CHART "INVESTED (%)"  -->

            <section
              :id="sections[DetailsPageSections.InvestedChart].id"
              class="mt-8 lg:mt-7"
              :class="sections[DetailsPageSections.InvestedChart].order"
            >
              <IssueDetailsV2InvestedChart
                :issue="issue"
                :support-url="SUPPORT_URL"
              />
            </section>

            <!--   INSOLVENCY, LUSTRATIONS ETC.   -->

            <LazyIssueDetailsV2Warnings
              v-if="sections[DetailsPageSections.Warnings].isAvailable"
              :id="sections[DetailsPageSections.Warnings].id"
              class="mt-6 lg:mt-16"
              :class="sections[DetailsPageSections.Warnings].order"
              :issue="issue"
              :warnings="sections[DetailsPageSections.Warnings].data"
            />

            <!--  CALCULATOR + CTA (MOBILE)  -->

            <template v-if="sections[DetailsPageSections.MobileCalculator].isAvailable">
              <section
                :id="sections[DetailsPageSections.MobileCalculator].id"
                class="mt-10"
                :class="sections[DetailsPageSections.MobileCalculator].order"
              >
                <LazyIssueDetailsV2Calculator
                  v-model="investmentCount"
                  :issue="issue"
                  :order-url="orderURL"
                  :order-url-type="orderUrlType"
                  @buy:issue="
                    handleIssueBuy(DataLayerLocationsEnum.IssueDetails)
                  "
                />
              </section>

              <!--  CONTACT CARD (MOBILE)  -->

              <section
                :id="sections[DetailsPageSections.ContactCard].id"
                class="mt-14"
                :class="sections[DetailsPageSections.ContactCard].order"
              >
                <ContactCard
                  :button-stroke="orderURL"
                  :title="$t('contact.card.title')
                  "
                  @email:click="
                    onEmailClickEvent({
                      entity: { issue },
                      location: DataLayerLocationsEnum.IssueDetails,
                    })
                  "
                  @lead-dialog-opened="
                    onMoreInfoContactClickEvent({
                      entity: { issue },
                      location: DataLayerLocationsEnum.IssueDetails,
                    })
                  "
                  @message:click="onCallpageMainOpenEvent(DataLayerLocationsEnum.IssueDetailsBox, issue)"
                  @phone:click="
                    onPhoneClickEvent({
                      entity: { issue },
                      location: DataLayerLocationsEnum.IssueDetails,
                    })
                  "
                />
              </section>
            </template>

            <!--  MOTIVATION  -->

            <section
              v-if="sections[DetailsPageSections.ReasonsToInvest].isAvailable"
              class="mt-10 lg:mt-16"
              :class="sections[DetailsPageSections.ReasonsToInvest].order"
            >
              <IssueDetailsV2ReasonsToInvest
                :id="sections[DetailsPageSections.ReasonsToInvest].id"
                :issue="issue"
              />
            </section>

            <!-- MONEY USAGE PLAN -->
            <section
              class="mt-10 lg:mt-16"
              :class="sections[DetailsPageSections.MoneyUsagePlan].order"
            >
              <MoneyUsagePlan
                :id="sections[DetailsPageSections.MoneyUsagePlan].id"
                :issue="issue"
              />
            </section>

            <!-- PARAMETERS -->
            <section
              v-if="sections[DetailsPageSections.Parameters].isAvailable"
              :id="sections[DetailsPageSections.Parameters].id"
              class="mt-10 lg:mt-16"
              :class="sections[DetailsPageSections.Parameters].order"
            >
              <LazyIssueDetailsV2Parameters
                :issue="issue"
                :parameters="sections[DetailsPageSections.Parameters].data"
              />
            </section>

            <!--  RETURN CALENDAR  -->

            <LazyIssueDetailsV2ReturnCalendarSection
              :id="sections[DetailsPageSections.ReturnCalendar].id"
              class="mt-10 lg:mt-16"
              :class="sections[DetailsPageSections.ReturnCalendar].order"
              :issue="issue"
              @buy:issue="handleIssueBuy"
              @close:sidesheet="onSrovSidesheetClose(issue, 'yield')"
              @open:sidesheet="onSrovSidesheetClick(issue, 'yield')"
            />

            <!-- BLOG POSTS -->

            <section
              v-if="sections[DetailsPageSections.BlogPosts].isAvailable"
              class="mt-10 lg:mt-16"
              :class="sections[DetailsPageSections.BlogPosts].order"
            >
              <BlogListings
                :id="sections[DetailsPageSections.BlogPosts].id"
                :blog-posts="blogPosts"
                :title="$t('issue.blogPosts')"
                @data-layer-update="handleCommentsDataLayerUpdate"
              />
            </section>

            <!--  COMPANY ECONOMIC RESULT  -->
            <section
              class="mt-10 lg:mt-16"
              :class="sections[DetailsPageSections.CompanyEconomicResults].order"
            >
              <CompanyDetailsEconomicResults
                :id="sections[DetailsPageSections.CompanyEconomicResults].id"
                :company="issue.company?.data.attributes || null"
                :is-data-available="sections[DetailsPageSections.CompanyEconomicResults].isAvailable"
                :sidesheet-caption="$t('issue.issueName.title', { issueName: issue.name })"
                :sidesheet-title="`${$t('company.economicResults.title')} ${issue.company?.data.attributes.name}`"
                @buy:issue="handleIssueBuy"
                @close:sidesheet="onSrovSidesheetClose(issue, 'financial_results')"
                @open:sidesheet="onSrovSidesheetClick(issue, 'financial_results')"
              />
            </section>

            <section
              v-if="sections[DetailsPageSections.News].isAvailable"
              class="mt-10 lg:mt-16"
              :class="sections[DetailsPageSections.News].order"
            >
              <IssueDetailsV2News
                v-if="issue"
                :id="sections[DetailsPageSections.News].id"
                :issue="issue"
              />
            </section>
            <!--  DOCUMENTS  -->

            <section
              class="mt-10 lg:mt-16"
              :class="sections[DetailsPageSections.Documents].order"
            >
              <IssueDetailsV2Documents
                :id="sections[DetailsPageSections.Documents].id"
                :is-data-available="sections[DetailsPageSections.Documents].isAvailable"
                :issue="issue"
                @buy:issue="handleIssueBuy"
                @close:sidesheet="onSrovSidesheetClose(issue, 'documents')"
                @open:sidesheet="onSrovSidesheetClick(issue, 'documents')"
              />
            </section>

            <!--  COLLATERALS  -->

            <section
              class="mt-10 lg:mt-16"
              :class="sections[DetailsPageSections.Collaterals].order"
            >
              <IssueDetailsV2Collaterals
                :id="sections[DetailsPageSections.Collaterals].id"
                :issue="issue"
                @buy:issue="handleIssueBuy"
                @close:sidesheet="
                  onSrovSidesheetClose(issue, 'collateralization')
                "
                @open:contact-issuer="
                  handleContactIssuerAboutDialogOpen('banner')
                "
                @open:sidesheet="
                  onSrovSidesheetClick(issue, 'collateralization')
                "
              />
            </section>

            <!--  INVESTOR PROMO BANNER  -->

            <section
              v-if="sections[DetailsPageSections.InvestorPromo].isAvailable"
              class="mt-10 lg:mt-16"
              :class="sections[DetailsPageSections.InvestorPromo].order"
            >
              <LazyInvestorPromoBanner direction="col" />
            </section>

            <!--  COMPANY DETAILS  -->

            <section
              v-if="sections[DetailsPageSections.CompanyDetails].isAvailable"
              class="mt-10 lg:mt-16"
              :class="sections[DetailsPageSections.CompanyDetails].order"
            >
              <IssueDetailsV2CompanyDetails
                :id="sections[DetailsPageSections.CompanyDetails].id"
                :issue="issue"
                @buy:issue="handleIssueBuy"
                @close:sidesheet="onSrovSidesheetClose(issue, 'about')"
                @get-issuer-contact="
                  handleContactIssuerAboutDialogOpen('about')
                "
                @open:sidesheet="onSrovSidesheetClick(issue, 'about')"
              />
            </section>

            <!-- RISK RATING -->

            <section
              v-if="sections[DetailsPageSections.RiskRating].isAvailable"
              class="mt-10 lg:mt-16"
              :class="sections[DetailsPageSections.RiskRating].order"
            >
              <IssueDetailsV2Risk
                :id="sections[DetailsPageSections.RiskRating].id"
                :issue="issue"
                @buy:issue="handleIssueBuy"
                @close:sidesheet="onSrovSidesheetClose(issue, 'score')"
                @open:sidesheet="onSrovSidesheetClick(issue, 'score')"
              />
            </section>

            <!-- INVESTMENT EXAMPLE -->

            <section
              v-if="sections[DetailsPageSections.InvestmentExample].isAvailable"
              class="mt-10 lg:mt-16"
              :class="sections[DetailsPageSections.InvestmentExample].order"
            >
              <IssueDetailsV2InvestmentExample
                :id="sections[DetailsPageSections.InvestmentExample].id"
                :issue="issue"
              />
            </section>

            <!-- OTHER ISSUER ISSUES -->

            <section
              v-if="sections[DetailsPageSections.ShowMoreIssuerIssues].isAvailable"
              class="mt-10 lg:mt-16"
              :class="sections[DetailsPageSections.ShowMoreIssuerIssues].order"
            >
              <LazyIssueDetailsV2Issues
                :id="sections[DetailsPageSections.ShowMoreIssuerIssues].id"
                :button-label="$t('issue.showMoreIssuerIssues.button.label')"
                :company-id="issue.company?.data?.id"
                is-issuer-issue
                :issues="issue.company?.data?.attributes?.issues?.data || []"
                :title="
                  $t('issue.showMoreIssuerIssues.title', {
                    companyName: issue.company?.data?.attributes?.name,
                  })
                "
                @buy:issue="handleMoreIssueBuy"
              />
            </section>

            <!-- OTHER ISSUES -->

            <section
              v-if="sections[DetailsPageSections.ShowMoreIssues].isAvailable"
              class="mt-10 lg:mt-16"
              :class="sections[DetailsPageSections.ShowMoreIssues].order"
            >
              <LazyIssueDetailsV2Issues
                :id="sections[DetailsPageSections.ShowMoreIssues].id"
                :button-label="$t('issue.showMoreIssues.button.label')"
                :issues="issue.showMoreIssues || []"
                :title="$t('issue.showMoreIssues.title')"
                @buy:issue="handleMoreIssueBuy"
              />
            </section>

            <!-- ADVERTISING MESSAGE -->
            <LazyIssueDetailsV2AdvertisingMessageSection
              v-if="sections[DetailsPageSections.AdvertisingMessage].isAvailable"
              class="mt-6"
              :class="sections[DetailsPageSections.AdvertisingMessage].order"
              :is-investment-example-available="sections[DetailsPageSections.InvestmentExample].isAvailable"
              :issue="issue"
            />

            <!-- NOT COMPLETED ISSUE -->
            <LazyIssueDetailsV2NotCompleteIssue
              v-if="sections[DetailsPageSections.NotCompletedIssue].isAvailable"
              class="mt-6"
              :class="sections[DetailsPageSections.NotCompletedIssue].order"
            />
          </div>
        </main>

        <!-- FLOATING RIGHT COL -->
        <aside
          v-if="responsive.tablet || responsive.desktop"
          class="hidden lg:relative lg:col-span-5 lg:flex xl:pl-5"
        >
          <LazyBaseOverflowFloating :offset-y="HEADER_HEIGHT_DESKTOP_WITH_ISSUE_NAVIGATION">
            <div class="flex flex-col gap-y-7 pb-5">
              <template v-if="issue">
                <div v-if="investorIssueInvestments" class="space-y-4 rounded-xl bg-brand-light p-6 shadow-[0_0_8px_0_#2C2C3F1A]">
                  <div class="flex items-baseline justify-between">
                    <CfgTypography :size="CfgTypographySizes.h3">
                      Moje investice
                    </CfgTypography>

                    <CfgTypography class="space-x-2" :size="CfgTypographySizes.md">
                      <span class="font-semibold">{{ useMoneyFormat(investorIssueInvestments, true) }} {{ $t(`common.currencies.${issue.currency}`) }}</span>

                      <span class="text-secondary">({{ investorIssueInvestments / issue.nominalValue! }} ks)</span>
                    </CfgTypography>
                  </div>

                  <NuxtLink class="block" :to="`${investorAppUrl}/portfolio/${issue.isin}`">
                    <CfgButton
                      class="w-full bg-white py-4 lg:py-2.5"
                      flat
                      stroke
                      variant="primary"
                    >
                      Detail investice
                    </CfgButton>
                  </NuxtLink>
                </div>

                <div class="rounded-xl p-6 shadow-md">
                  <IssueDetailsV2Calculator
                    v-model="investmentCount"
                    :has-data="Boolean(
                      issue.maturityDate
                        && issue.nominalValue
                        && issue.interestRate?.annualInterestRate,
                    )
                    "
                    :issue="issue"
                    :order-url="orderURL"
                    :order-url-type="orderUrlType"
                    @buy:issue="
                      handleIssueBuy(DataLayerLocationsEnum.IssueDetails)
                    "
                  />

                  <div v-if="sections[DetailsPageSections.InvestorPromo].isAvailable" class="flex items-center gap-2 pt-4 text-secondary">
                    <QuestionCircleIcon class="text-[20px]" />

                    <CfgTypography
                      class="!font-normal"
                      :html="$t('issue.heroSection.calculator.investorAppHint')"
                      :size="CfgTypographySizes.xs"
                      tag-name="p"
                    />
                  </div>
                </div>

                <div class="rounded-xl p-6 shadow-md">
                  <ContactCard
                    :button-stroke="orderURL"
                    :title="$t('contact.card.title')"
                    @email:click="
                      onEmailClickEvent({
                        entity: { issue },
                        location: DataLayerLocationsEnum.IssueDetails,
                      })
                    "
                    @lead-dialog-opened="
                      onMoreInfoContactClickEvent({
                        entity: { issue },
                        location: DataLayerLocationsEnum.IssueDetails,
                      })
                    "
                    @message:click="onCallpageMainOpenEvent(DataLayerLocationsEnum.IssueDetailsBox, issue)"
                    @phone:click="
                      onPhoneClickEvent({
                        entity: { issue },
                        location: DataLayerLocationsEnum.IssueDetails,
                      })
                    "
                  />
                </div>
              </template>
            </div>
          </LazyBaseOverflowFloating>
        </aside>
      </div>

      <!-- MODALS -->
      <ClientOnly>
        <IssueDetailsV2CompletenessModal
          v-model:is-opened="isIssueCompletenessModalOpened"
          :percentage="completenessPercentage"
          :summary="summary"
        />

        <LazyModalTax />

        <LazyContactIssuerDialog
          v-model="isContactIssuerDialogOpened"
          @submit:error="handleContactIssuerErrorDialogOpen"
          @submit:success="handleIssuerSuccessDialogOpen"
        />

        <LazyContactIssuerSuccessDialog
          v-model:opened="isContactIssuerSuccessDialogOpened"
        />

        <LazyContactIssuerErrorDialog
          v-model:opened="isContactIssuerErrorDialogOpened"
        />

        <LazyModalOrder
          :company-name="issueToBuy?.company?.data?.attributes?.name"
          :is-opened="isIssueBuyModalOpen"
          :issue-name="issueToBuy?.name"
          :order-url="orderUrl"
          :type="issueBuyModalType"
          @click-close:backdrop="handleIssueBuyModalClose('backdrop')"
          @click-close:button="handleIssueBuyModalClose('button')"
          @click-close:custom="handleIssueBuyModalClose('custom')"
          @open:order-form="handleBuyNowEventClick"
        />
      </ClientOnly>

      <!-- ORDER ONLINE BUTTON (MOBILE) -->
      <IssueDetailsV2OrderOnlineButton
        v-if="orderURL"
        class="left-2 right-36 min-[400px]:left-auto lg:hidden"
        :order-url-type="orderUrlType || IssueBuyUrlTypeEnum.JAMES"
        @click="handleIssueBuy(DataLayerLocationsEnum.Floating)"
      />
    </div>

    <ClientOnly v-else>
      <div class="flex flex-1 items-center justify-center py-14 text-brand">
        <CfgPreloader />
      </div>
    </ClientOnly>
  </div>
</template>

<style>
html {
  scroll-padding-top: 150px !important;
}
</style>
